:root {
  --addarkbgcolor: #1a0029;
  --bglightcolor: #eaeaea;
  --adbasebgcolor: #24003d;
  --adbaseactcolor: #2f0050;
  --adbasehvcolor: rgb(71, 3, 119);
  --adtextbgcolor: rgb(163, 141, 179);
  --bodyfontsize: 10px;
}

.adnav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 20px;
  background-color: var(--adbasebgcolor);
  color: white;
  padding: 8px;
  overflow-y: auto;
  width: 290px;
  border-radius: 20px;
  position: fixed;
  z-index: 1000;
  height: 97%;
}

.addLiIcon {
  font-size: 25px;
  margin-right: 20px;
}

.ad-logo {
  display: flex;
  position: relative;
  justify-content: center;
}

.ad-logo::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background: linear-gradient(to left, transparent, #ddd, transparent) center;
}

.adlogoimg {
  width: 100px;
  height: 100px;
}

.adulLi {
  list-style: none;
  margin-top: 10px;
}

.admLi {
  margin: 20px 0px;
}

.activeAddLi {
  text-decoration: none;
  margin-left: -20px;
  padding: 15px;
  width: 100%;
  color: white;
  border-radius: 10px;
  font-weight: 600;
  display: inline-block;
}

.activeAddLi {
  background-color: var(--adbaseactcolor);
}

.addLi {
  text-decoration: none;
  margin-left: -20px;
  padding: 15px;
  font-weight: 600;
  width: 100%;
  color: white;
  border-radius: 10px;
  display: inline-block;
  transition: 0.5s;
}

.addLi:hover {
  background-color: var(--adbasehvcolor);
  transition: 0.5s;
}

.admore {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.admoreLi {
  text-decoration: none;
  padding: 15px;
  color: white;
  border-radius: 10px;
  background-color: var(--adbaseactcolor);
  display: inline-block;
}

.admoreLiIcon {
  vertical-align: middle;
  font-size: 25px;
  margin-right: 10px;
}

.admoreLi:hover {
  transition: 0.4s;
  background-color: var(--adbasehvcolor);
}

.admbnav {
  display: none;
  position: fixed;
  z-index: 1000;
  bottom: 0;
  width: 100%;
  background: rgba(36, 0, 61, 0.6);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  color: white;
}

.admbnavUl {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.admbnavLi {
  position: relative;
  padding: 10px 15px 0px 15px;
  margin-left: -16px;
}

.adnavmblink {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: #b1b1b1;
  align-items: center;
  transition: 0.5s;
}

.adnavmblink:hover {
  color: var(--adbasehvcolor);
  transition: 0.5s;
}

.admbnavactLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: var(--adbasehvcolor);
}

.admbnavactLink .admbnavtext {
  color: white;
  transition: 0.5s;
}

.adnavmblink .admbnavtext {
  transition: 0.5s;
}

.adnavmblink:hover .admbnavtext {
  color: white;
  transition: 0.5s;
}

.adnavmbIcon {
  font-size: 1em;
}

.admbnavtext {
  font-size: 0.7em;
  position: relative;
  top: 5px;
  font-weight: 600;
}

.adnavsupport {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.adnavuntick {
  background-color: var(--addarkbgcolor);
  color: white;
  padding: 5px 10px;
  border-radius: 50%;
}

.admbnavuntick {
  background-color: var(--adbasehvcolor);
  color: white;
  padding: 5px 10px;
  border-radius: 50%;
  position: absolute;
  left: 40px;
  top: -10px;
}

@media (max-width: 800px) {
  .adnav {
    display: none;
  }
  .admbnav {
    display: block;
  }
}
