.hide {
  display: none;
  visibility: hidden;
}

.logaside {
  overflow-y: auto;
  width: 290px;
  position: fixed;
  top: 0;
  z-index: 1000;
  height: 100%;
  background-color: white;
  -webkit-box-shadow: 15px 2px 10px -10px #ddd;
  -moz-box-shadow: 15px 2px 10px -10px #ddd;
  box-shadow: 15px 2px 10px -10px #ddd;
  scrollbar-width: thin;
  scrollbar-color: #888;
}

.dark-aside {
  background-color: rgb(12, 12, 12);
  color: white;
  -webkit-box-shadow: 7px 0.5px 4.5px -2px rgb(20, 20, 20);
  -moz-box-shadow: 7px 0.5px 4.5px -2px rgb(20, 20, 20);
  box-shadow: 7px 0.5px 4.5px -2px rgb(20, 20, 20);
  overflow-y: auto;
  width: 290px;
  position: fixed;
  z-index: 1000;
  height: 100%;
  scrollbar-width: thin;
  scrollbar-color: #888 #333;
}

.logaside .hjfgh,
.dark-aside .hjfgh {
  list-style-type: none;
}

.logaside ul li,
.dark-aside ul li {
  margin-top: 10px;
  margin-left: -10px;
  margin-right: 20px;
}

.dark-aside ul li a {
  color: white;
}
.logaside ul li a {
  color: black;
}

#mtg {
  margin-bottom: 40px;
}

.dark-link-cd:hover {
  transition: 0.5s;
  background-color: var(--bgdarkcolor);
  border: 1px solid var(--stbaseactcolor);
}

.link-cd,
.dark-link-cd {
  text-decoration: none;
  display: inline-block;
  width: 100%;
  font-size: 1rem;
  transition: 0.5s;
  font-weight: 700;
  padding: 20px;
  border-radius: 20px;
}

.link-cd {
  border: 1px solid white;
}

.dark-link-cd {
  border: 1px solid rgb(12, 12, 12);
}

.link-cd:hover {
  transition: 0.5s;
  background-color: var(--bglightcolor);
  border: 1px solid var(--stbaseactcolor);
}

.link-cd .icons-li,
.dark-link-cd .icons-li {
  font-size: 1.5rem;
  color: #8b8a8a;
  margin-right: 13px;
}

.link-cd:hover .icons-li,
.dark-link-cd:hover .icons-li {
  color: var(--stbasehvcolor);
}

.light-link-cd {
  text-decoration: none;
  display: inline-block;
  width: 100%;
  font-size: 1rem;
  transition: 0.5s;
  font-weight: 600;
  padding: 20px;
  border-radius: 20px;
  transition: 0.5s;
  background-color: var(--bglightcolor);
  border: 1px solid var(--stbaseactcolor);
}

.light-link-cd .active-icon {
  color: var(--stbasehvcolor);
  font-size: 1.5rem;
  margin-right: 13px;
}

.dark-active-link .active-icon {
  color: var(--stbasehvcolor);
  font-size: 1.5rem;
  margin-right: 13px;
}

.dark-active-link {
  text-decoration: none;
  display: inline-block;
  width: 100%;
  font-size: 1rem;
  transition: 0.5s;
  font-weight: 600;
  padding: 20px;
  border-radius: 20px;
  background-color: var(--bgdarkcolor);
  border: 1px solid var(--stbaseactcolor);
}

.clorderaddic {
  height: 1.6rem;
  width: 1.6rem;
  margin-right: 13px;
}

.clnavsupport {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.clnavuntick {
  background-color: var(--stbaseactcolor);
  color: white;
  padding: 5px 10px;
  border-radius: 50%;
}

.clskeletoncon {
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.skeleton {
  background-color: #e0e0e0;
  background-image: linear-gradient(90deg, #e0e0e0, #f8f8f8, #e0e0e0);
  background-size: 200% 100%;
  background-position: 200% center;
  animation: skeleton-loading 2.5s infinite;
  border-radius: 20px;
  height: 60px;
}

.dark-skeleton {
  background-color: #333;
  background-image: linear-gradient(90deg, #333, #444, #333);
  background-size: 200% 100%;
  background-position: 200% center;
  animation: skeleton-loading 2.5s infinite;
  border-radius: 20px;
  height: 60px;
}

@keyframes skeleton-loading {
  0% {
    background-position: 200% center;
  }
  100% {
    background-position: -200% center;
  }
}

@media (max-width: 800px) {
  .logaside {
    display: none;
  }
  .dark-aside {
    display: none;
  }
}
