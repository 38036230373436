[data-theme="dark"] {
  --bgcolor: var(--bgdarkcolor);
  --bgmaincolor: #161616;
  --textcolor: white;
  --panelcolor: var(--stbasebgcolor);
  --panelhovercolor: var(--stbasehvcolor);
  --panelactioncolor: var(--stbaseactcolor);
}

[data-theme="light"] {
  --bgcolor: var(--bglightcolor);
  --bgmaincolor: #fcfcfc;
  --textcolor: black;
  --panelcolor: var(--stbasebgcolor);
  --panelhovercolor: var(--stbasehvcolor);
  --panelactioncolor: var(--stbaseactcolor);
}

.clblogscon {
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  background-color: var(--bgcolor);
  color: var(--textcolor);
}

.clblogsmaincon {
  margin: 100px 20px 20px 360px;
}

.clallblogs {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.clblogPreview {
  background-color: var(--bgmaincolor);
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.clblogPreview:hover {
  transform: translateY(-10px);
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.3);
}

.clblogTitle {
  font-weight: bold;
  color: var(--panelactioncolor);
  margin-bottom: 10px;
}

.clblogExcerpt {
  color: var(--textcolor);
  margin-bottom: 20px;
}

.clblogviewMoreButton {
  padding: 10px 20px;
  background-color: var(--panelactioncolor);
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.clblogviewMoreButton:hover {
  transition: background-color 0.3s ease;
  background-color: var(--panelhovercolor);
}

.clblogdetails {
  background-color: var(--bgmaincolor);
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.clblogtitle h3 {
  font-weight: 600;
}

.clblogtitle {
  border-bottom: 1px solid var(--panelcolor);
}

.clblogcontent {
  margin-top: 20px;
}

@media (max-width: 800px) {
  .clblogscon {
    overflow: unset;
    font-size: var(--bodyfontsize);
  }
  .clblogsmaincon {
    margin: 100px 20px;
  }
}
