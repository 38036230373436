:root {
  --darkbkcolor: #181818;
  --lightbkcolor: #ddd;
}

.cldarkhpcon,
.cllighthpcon {
  position: relative;
  top: 100px;
}

.cldarkhpcon {
  color: white;
}

.hometutorialcon {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tutorialHomePage {
  border-radius: 20px;
  height: 560px;
  width: 760px;
}

.clhpheader {
  width: 70%;
  margin: auto;
  min-height: 100vh;
  padding: 20px;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.clhphdtxt {
  flex: 1 1 700px;
  display: flex;
  flex-direction: column;
}

.clhpheader .hdtxt {
  font-weight: 700;
}

.clhpheader .hdtxt span {
  color: var(--stbaseactcolor);
}

.clhphdtxt a {
  text-decoration: none;
  width: fit-content;
  color: white;
  transition: 0.3s;
  padding: 20px 55px;
  margin-top: 30px;
  border-radius: 10px;
  background-color: var(--stbaseactcolor);
}

.clhphdtxt a:hover {
  background-color: var(--stbasehvcolor);
  transition: 0.3s;
}

.clhpheader img {
  flex: 1 1 300px;
  width: 300px;
  pointer-events: none;
}

.clhpfeatures,
.clhpfaqs,
.clhpapiint {
  width: 70%;
  margin: auto;
}

.clhpfeats {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
}

.cldarkhpcon .clhpfeat {
  box-shadow: 0 4px 6px var(--darkbkcolor);
  color: white;
  background-color: black;
}

.cllighthpcon .clhpfeat {
  color: black;
  box-shadow: 0 4px 6px var(--lightbkcolor);
  background-color: white;
}

.clhpfeat {
  border-radius: 10px;
  margin: 20px;
  padding: 20px;
  flex: 1 1 300px;
  max-width: 400px;
}

.clhpfeat .clhpfeattext {
  padding: 10px;
}

.clhpfeat .clhpfthdtxt {
  font-weight: 700;
}

.clhpfeat .clhpfeaticon {
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: center;
}

.clhpfeat .clhpfeaticon .clhpfticon {
  font-size: 1.3rem;
  color: var(--stbaseactcolor);
}

.clhpapiintcon {
  min-height: 100vh;
  padding: 20px;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.clhpapitxt {
  flex: 1 1 700px;
  display: flex;
  flex-direction: column;
}

.clhpapitxt .clhpanimlink {
  margin-top: 30px;
}

.clhpapitxt a {
  text-decoration: none;
  width: fit-content;
  color: white;
  transition: 0.3s;
  padding: 20px 55px;
  border-radius: 10px;
  background-color: var(--stbaseactcolor);
}

.clhpapitxt a:hover {
  background-color: var(--stbasehvcolor);
  transition: 0.3s;
}

.clhpapiint:not(:first-child) {
  margin-top: 20px;
}

.clhpapiintcon .clhpanimimg img {
  pointer-events: none;
  width: 100%;
}

.clhpapiintcon .clhpanimimg {
  flex: 1 1 300px;
  width: 300px;
}

.cldarkhpcon .clhpfaq {
  border: 1px solid var(--darkbkcolor);
  color: white;
}

.cllighthpcon .clhpfaq {
  border: 1px solid var(--lightbkcolor);
}

.clhpfaq {
  border-radius: 10px;
  width: 70%;
  cursor: pointer;
  margin: 20px 0px;
}

.clhpfaquest {
  font-weight: 700;
}

.clhpfaquest,
.clhpfaqans {
  padding: 20px;
}

.clhpfaqicon {
  font-size: 1.2rem;
  margin-right: 20px;
}

@media (max-width: 1000px) {
  .clhpheader,
  .clhpfeatures,
  .clhpapiint,
  .clhpfaqs {
    width: 90%;
  }
  .clhpapiint .clhphptropd {
    margin-top: 30px;
  }
  .clhpfaq {
    width: 100%;
  }
}
