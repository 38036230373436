:root{
    --sitecolor: #333;
}

.no-orders{
    margin-top: 30px;
    background-color: white;
    color: #000;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 20px;
    text-align: center;
}

.dark-no-orders{
    margin-top: 30px;
    min-width: 0px;
    background-color: black;
    color: white;
    width: 100%;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 20px;
    text-align: center;
}

.kvkbf{
    width: 60px;
    height: 60px;
}

.jjvss{
    text-decoration: none;
    display: inline-block;
    color: var(--sitecolor);
}

@media(max-width:800px){
    .no-orders{
        min-width: 100%;
    }
    .dark-no-orders{
        min-width: 100%;
    }
}