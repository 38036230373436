[data-theme="dark"] {
  --bgcolor: var(--bgdarkcolor);
  --bgmaincolor: #121212;
  --textcolor: white;
}

[data-theme="light"] {
  --bgcolor: var(--bglightcolor);
  --bgmaincolor: #f3f1f1;
  --textcolor: #333;
}

.cldetails-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.cldetails-overlay-con {
  background-color: var(--bgmaincolor);
  color: var(--textcolor);
  padding: 20px;
  width: 600px;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  border-radius: 10px;
}

.cldetailsHeadtext {
  font-weight: bold;
  color: var(--sttextbgcolor);
}

.cldetailsBodyText {
  padding: 15px;
  border: 1px solid var(--bgcolor);
  border-radius: 15px;
  font-family: inherit;
  white-space: pre-wrap;
  margin-top: 20px;
}
