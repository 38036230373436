[data-theme="dark"] {
  --bgcolor: var(--bgdarkcolor);
  --bgmaincolor: #121212;
  --textcolor: white;
  --textbgcolor: var(--sttextbgcolor);
  --actioncolor: var(--stbaseactcolor);
  --hovercolor: var(--stbasehvcolor);
}

[data-theme="light"] {
  --bgcolor: var(--bglightcolor);
  --bgmaincolor: #f3f1f1;
  --textcolor: #333;
  --textbgcolor: var(--sttextbgcolor);
  --actioncolor: var(--stbaseactcolor);
  --hovercolor: var(--stbasehvcolor);
}

.view-orders-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.view-orders-con {
  width: 600px;
  max-height: 80%;
  overflow-y: auto;
  background-color: var(--bgmaincolor);
  color: var(--textcolor);
  animation: slideIn 1s forwards;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.view-order-info-con {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.view-order-info {
  border-radius: 10px;
  background-color: var(--bgcolor);
}

.view-order-info-head {
  display: flex;
  justify-content: center;
  padding: 20px;
  align-items: center;
  border-bottom: 1px solid var(--actioncolor);
}

.view-order-info-body {
  padding: 20px;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.view-order-info-head span {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    to bottom right,
    var(--sttextbgcolor),
    var(--sitecolor)
  );
  background-clip: text;
  font-weight: 600;
}

.view-order-info-triple {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  background-color: var(--bgcolor);
}

.view-order-info-triple .quantity,
.view-order-info-triple .charge,
.view-order-info-triple .balance-before,
.view-order-info-triple .balance-after,
.view-order-info-triple .date {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.view-order-info-triple .quantity span:nth-child(1),
.view-order-info-triple .charge span:nth-child(1),
.view-order-info-triple .balance-before span:nth-child(1),
.view-order-info-triple .balance-after span:nth-child(1),
.view-order-info-triple .date span:nth-child(1) {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    to bottom right,
    var(--sttextbgcolor),
    var(--sitecolor)
  );
  background-clip: text;
  font-weight: 600;
}

.view-order-info-triple .quantity span:nth-child(2),
.view-order-info-triple .charge span:nth-child(2),
.view-order-info-triple .balance-before span:nth-child(2),
.view-order-info-triple .balance-after span:nth-child(2),
.view-order-info-triple .date span:nth-child(2) {
  font-weight: 600;
  font-size: calc(1em + 0.2rem);
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.clviewcommentsbtn,
.clrefillbtn {
  border: 2px solid var(--stbaseactcolor);
  border-radius: 15px;
  display: flex;
  align-items: center;
  color: var(--stbaseactcolor);
  font-weight: 600;
  padding: 10px 20px;
  transition: 0.5s ease;
  background-color: transparent;
}

.clviewcommentsbtn:hover,
.clrefillbtn:hover {
  color: white;
  background-color: var(--stbaseactcolor);
}

.clviewcommentsbtn .icon,
.clrefillbtn .icon {
  font-size: 1.3rem;
  margin-right: 15px;
}

.clrefillbtn .icon {
  color: green;
}
.clviewcommentsbtn .icon {
  color: blue;
}

.view-orders-con .cancel-button {
  color: var(--textcolor);
}

@media (max-width: 800px) {
  .view-orders-con {
    width: 90%;
    font-size: var(--bodyfontsize);
  }
  .view-order-copy-btn {
    padding: 8px 12px;
  }
}
