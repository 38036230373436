:root {
  --selectfs: 1rem;
  --input-border-color: black;
  --input-bg-color: white;
  --input-text-color: black;
  --shadow-color: rgba(0, 0, 0, 0.1);
}

[data-theme="dark"] {
  --bgcolor: var(--bgdarkcolor);
  --bgmaincolor: #121212;
  --textcolor: white;
  --input-border-color: gray;
  --input-bg-color: black;
  --input-text-color: white;
  --shadow-color: rgba(255, 255, 255, 0.1);
}

[data-theme="light"] {
  --bgcolor: var(--bglightcolor);
  --bgmaincolor: #f9f9f9;
  --textcolor: #333;
}

.gjh {
  min-height: 100vh;
  overflow-y: auto;
  background-color: var(--bgcolor);
  color: var(--textcolor);
}

.order-container {
  position: relative;
  margin: 0px 20px 200px 360px;
  top: 100px;
}

.addorder {
  min-width: 50%;
  background-color: var(--input-bg-color);
  color: var(--input-text-color);
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 4px 15px var(--shadow-color);
  height: fit-content;
  margin-bottom: 20px;
}

.addorder h3 {
  font-weight: 600;
}

.addorder form {
  display: flex;
  flex-direction: column;
}

.addorder form textarea.input-field {
  height: 150px;
}

.input-field {
  margin-bottom: 20px;
  border-radius: 20px;
  padding: 10px;
  background-color: var(--input-bg-color);
  outline: 2px solid var(--input-border-color);
  border: none;
  color: var(--input-text-color);
  transition: outline 0.3s;
}

.input-field:focus {
  outline: 2px solid var(--stbaseactcolor);
  transition: outline 0.3s;
  box-shadow: 0 0 0 0 var(--sitecolor);
}

.Num::-webkit-inner-spin-button,
.Num::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.brd {
  margin-top: 30px;
  margin-bottom: 20px;
  border-top: 1px solid #ddd;
}

.btn-order {
  background-color: var(--sitecolor);
  color: white;
  font-weight: 600;
  margin-top: 10px;
  border-radius: 10px;
  border: 1px solid var(--stbasehvcolor);
  padding: 15px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: 0.5s;
  cursor: pointer;
}

.btn-order:hover {
  transition: 0.5s;
  background-color: var(--stbasehvcolor);
}

.desc-container {
  height: fit-content;
  min-width: 35%;
  max-width: 50%;
  background-color: var(--input-bg-color);
  color: var(--input-text-color);
  margin-bottom: 20px;
  border-radius: 20px;
  box-shadow: 0 4px 15px var(--shadow-color);
}

.desc-container .desc-item {
  border-bottom: 1px solid var(--bgcolor);
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clorddesid {
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  background-color: var(--stbaseactcolor);
}

.desc-container .price-highlight {
  color: var(--stbasehvcolor);
  font-size: 1.1rem;
}

.desc-container pre {
  padding: 20px;
  font-size: inherit;
  font-family: inherit;
  white-space: pre-wrap;
}

.tutorial-container {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tutorial-frame {
  border-radius: 20px;
  height: 560px;
  width: 760px;
}

.fade-in {
  animation: fade-in 0.5s forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.cldotsanimation {
  width: 13.4px;
  height: 13.4px;
  background: var(--stbaseactcolor);
  color: var(--stbaseactcolor);
  border-radius: 50%;
  box-shadow: 22.4px 0, -22.4px 0;
  animation: dots-u8fzftmd 1s infinite linear alternate;
}

@keyframes dots-u8fzftmd {
  0% {
    box-shadow: 22.4px 0, -22.4px 0;
  }

  33% {
    box-shadow: 22.4px 0, -22.4px 0 rgba(71, 75, 255, 0.13);
    background: rgba(71, 75, 255, 0.13);
  }

  66% {
    box-shadow: 22.4px 0 rgba(71, 75, 255, 0.13), -22.4px 0;
    background: rgba(71, 75, 255, 0.13);
  }
}

@media (max-width: 800px) {
  :root {
    --selectfs: 0.7rem;
  }
  .gjh {
    overflow-y: unset;
    font-size: var(--bodyfontsize);
  }
  .order-container {
    margin-left: 20px;
  }
  .addorder,
  .desc-container {
    min-width: 100%;
  }
}
