.adstatistics {
  width: 100%;
  height: 100%;
  background-color: var(--addarkbgcolor);
  color: white;
}

.adstatisticsmaincon {
  position: relative;
  top: 20px;
  margin: 0px 20px 50px 360px;
}

.adstatschart-section {
  margin-bottom: 40px;
}

.adstatsheadtxt {
  display: flex;
  align-items: center;
}

.adstatsheadtxt .icon {
  font-size: 1.4rem;
  margin-right: 15px;
}

.adstatschart-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.adstatschart {
  background-color: var(--adbasebgcolor);
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.adstatschart:hover {
  transform: translateY(-5px);
}

.adstatschart h3 {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.adstatschart h3 .icon {
  font-size: 1.4rem;
  margin-right: 15px;
}

.adstatschart:nth-child(1) h3 .icon {
  color: #3cb371;
}

.adstatschart:nth-child(2) h3 .icon {
  color: #1e90ff;
}

.adstatschart:nth-child(4) h3 .icon {
  color: green;
}

.adstatschart:nth-child(5) h3 .icon {
  color: red;
}

.custom-tooltip {
  background-color: var(--adbasebgcolor) !important;
  border-radius: 10px !important;
  border: 2px solid var(--adbaseactcolor) !important;
}

.custom-tooltip-label {
  color: var(--adbaseactcolor) !important;
  font-weight: bold !important;
}

.custom-tooltip-item {
  color: var(--adbasehvcolor) !important;
}

.adstatstransaction-section .icon {
  color: var(--adbasehvcolor);
}

.adstatstransaction-section {
  margin-top: 40px;
  overflow-x: auto;
}

.adstatstransaction-table {
  border-radius: 10px;
  width: 100%;
  border-collapse: collapse;
  background-color: var(--adbasebgcolor);
}

.adstatstransaction-table th,
.adstatstransaction-table td {
  padding: 20px;
  text-align: left;
}

.adstatstransaction-table tr:not(:last-child),
.adstatstransaction-table th {
  border-bottom: 1px solid var(--addarkbgcolor);
}

.adstatstransaction-table .status-completed,
.adstatstransaction-table td .add {
  color: green;
  font-weight: bold;
}

.adstatstransaction-table .status-failed,
.adstatstransaction-table td .remove {
  font-weight: bold;
  color: #ff4d4d;
}

@media (max-width: 800px) {
  .adstatisticsmaincon {
    margin: 0px 20px 100px 20px;
    font-size: var(--bodyfontsize);
  }

  .adstatschart-wrapper {
    grid-template-columns: 1fr;
  }
}
