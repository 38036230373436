:root {
  --sitecolor: #333;
}

#mydarkbottomborder {
  border-bottom: 2px solid var(--bgdarkcolor);
}

#mylightbottomborder {
  border-bottom: 2px solid var(--bglightcolor);
}

.view-chat {
  background-color: var(--bglightcolor);
  color: black;
  min-height: 100vh;
  overflow-y: auto;
}

.dark-view-chat {
  background-color: var(--bgdarkcolor);
  color: white;
  min-height: 100vh;
  overflow-y: auto;
}

.view-chat-container {
  position: relative;
  top: 100px;
  margin-left: 320px;
  margin-right: 20px;
}

.dark-viewchat {
  width: 80%;
  background-color: black;
  color: white;
  border-radius: 10px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 200px;
}

.viewchat {
  width: 80%;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 200px;
}

.viewchat-con {
  max-height: 590px;
  overflow-y: auto;
  scroll-behavior: smooth;
  padding-right: 7px;
}

.viewchatCl {
  margin-left: auto;
  width: 50%;
}

.vc-mu {
  font-size: 45px;
  color: var(--sitecolor);
  margin-left: 10px;
  align-self: center;
}

.vc-su {
  font-size: 45px;
  color: var(--sitecolor);
  margin-right: 10px;
  align-self: center;
}

.vc-rcrr4d {
  font-size: 20px;
  margin-bottom: 20px;
  white-space: pre-wrap;
}

.vc-mscb,
.viewchatCl-msg {
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
  box-shadow: 0px 2px 5px 0px var(--sitecolor);
  padding: 15px;
  width: 100%;
  border: 2px solid var(--sitecolor);
  margin-top: 20px;
  word-wrap: break-word;
}

.vc-mscb {
  animation: fade-in 0.5s forwards;
}
.vc-mscb img,
.vc-adminmscb img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.vc-adminmscb {
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
  box-shadow: 0px 2px 20px 0px var(--stbaseactcolor);
  padding: 15px;
  width: 100%;
  border: 2px solid var(--stbasehvcolor);
  animation: fade-in 0.5s forwards;
  margin-top: 20px;
  word-wrap: break-word;
}

.vc-message.user {
  margin-left: auto;
  width: 50%;
}

.vc-message.chatSupport {
  margin-right: auto;
  width: 50%;
}

.dark-vc-txtms {
  background-color: black;
  color: white;
  width: 100%;
  padding: 20px;
  height: 180px;
  border-radius: 10px;
  outline-color: var(--sitecolor);
  border-color: gray;
}

.vc-txtms {
  width: 100%;
  padding: 20px;
  height: 180px;
  border-radius: 10px;
  outline-color: var(--sitecolor);
  border-color: gray;
}

.vc-txtms:focus,
.dark-vc-txtms:focus {
  border-color: var(--sitecolor);
  outline: 0;
  box-shadow: 0 0 0 0.25rem var(--stbaseactcolor);
}

.vc-subMesg {
  border-radius: 100px;
  padding: 20px;
  min-width: 30px;
  color: white;
  background-color: var(--sitecolor);
  border: none;
  transition: 0.5s;
}

.vc-subMesg:hover {
  background-color: var(--stbasehvcolor);
  transition: 0.5s;
}

.msgcontcolor {
  font-weight: 600;
  text-decoration: none;
  color: var(--sitecolor);
  transition: 0.5s;
}

.msgcontcolor:hover {
  text-decoration: underline;
  color: var(--stbaseactcolor);
  transition: 0.5s;
}

.clmaxwifomob {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.viewchat .ProseMirror,
.dark-viewchat .ProseMirror,
.support-container .ProseMirror {
  border: 1px solid var(--stbasebgcolor);
}

.viewchat .ProseMirror-focused,
.dark-viewchat .ProseMirror-focused,
.support-container .ProseMirror-focused {
  outline: 1px solid var(--stbaseactcolor);
}

@media (max-width: 800px) {
  .dark-view-chat,
  .view-chat {
    font-size: var(--bodyfontsize);
  }
  .clmaxwifomob {
    width: 95%;
  }
  .vc-rcrr4d {
    font-size: inherit;
  }
  .view-chat-container {
    margin-left: 0px;
    margin-right: 0px;
  }
  .dark-viewchat {
    width: 95%;
  }
  .viewchat {
    width: 95%;
  }
  .viewchatCl {
    margin-left: auto;
    width: 80%;
  }
  .vc-message.user {
    width: 80%;
  }
  .vc-message.support {
    width: 80%;
  }
}
