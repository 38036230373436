[data-theme="dark"] {
  --bgcolor: var(--bgdarkcolor);
  --bgmaincolor: #121212;
  --textcolor: white;
  --inputbgcolor: #1f1f1f;
  --inputbordercolor: #333;
}

[data-theme="light"] {
  --bgcolor: var(--bglightcolor);
  --bgmaincolor: #f9f9f9;
  --textcolor: #333;
  --inputbgcolor: #ffffff;
  --inputbordercolor: #ddd;
}

.claffiliate {
  width: 100%;
  min-height: 100vh;
  background-color: var(--bgcolor);
  overflow: auto;
  color: var(--textcolor);
}

.claffiliatemaincon {
  margin: 100px 20px 50px 320px;
}

.claffiliatecon {
  max-width: 800px;
  border-radius: 20px;
  padding: 20px;
  margin: 0px auto;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  background-color: var(--bgmaincolor);
}

.affiliate-top-container {
  text-align: center;
  margin-bottom: 40px;
}

.affiliate-top-container h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-weight: 700;
}

.affiliate-description {
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: var(--textcolor);
}

.affiliate-percentage {
  color: var(--stbaseactcolor);
  font-weight: bold;
}

.affiliate-link-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  flex-direction: column;
}

.affiliate-link {
  padding: 12px 15px;
  font-size: 18px;
  outline: 2px solid var(--inputbordercolor);
  border-radius: 10px;
  width: 100%;
  background-color: transparent;
  color: var(--textcolor);
  border: none;
  text-align: center;
}

.claffcopy-button {
  padding: 15px 30px;
  background-color: var(--stbaseactcolor);
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 600;
  display: flex;
  transition: 0.5s;
  gap: 15px;
  align-items: center;
  justify-content: center;
}

.claffcopy-button:hover {
  background-color: var(--stbasehvcolor);
  transition: 0.5s;
}

.claffcopy-icon {
  font-size: 1.2rem;
}

.affiliate-stats-container {
  display: flex;
  justify-content: space-around;
  margin-bottom: 40px;
  gap: 20px;
}

.affiliate-stats {
  background-color: var(--inputbgcolor);
  padding: 30px;
  border-radius: 20px;
  text-align: center;
  flex: 1;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.affiliate-icon {
  font-size: 30px;
  margin-bottom: 10px;
  color: var(--stbaseactcolor);
}

.affiliate-stats-container h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  font-weight: 600;
}

.affiliate-stats-container p {
  font-size: 1.2rem;
  margin: 0;
}

.affiliate-table-container {
  background-color: var(--inputbgcolor);
  padding: 20px;
  overflow-x: auto;
  border-radius: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.affiliate-table {
  width: 100%;
  border-collapse: collapse;
}

.affiliate-table th,
.affiliate-table td {
  padding: 15px;
  text-align: left;
  color: var(--textcolor);
}

.affiliate-table tr:not(:last-child) {
  border-bottom: 1px solid var(--bgmaincolor);
}

.affiliate-table th {
  background-color: var(--stbaseactcolor);
  color: white;
  font-weight: bold;
}

.affiliate-table th:first-child {
  border-top-left-radius: 10px;
}

.affiliate-table th:last-child {
  border-top-right-radius: 10px;
}

.affiliate-table tr:hover {
  background-color: var(--stbasehvcolor);
  color: white;
  transition: 0.5s;
}

@media (max-width: 800px) {
  .claffiliatemaincon {
    margin: 100px 20px;
    font-size: var(--bodyfontsize);
  }

  .affiliate-top-container h2 {
    font-size: 2rem;
  }
  .affiliate-stats-container {
    flex-direction: column;
    gap: 15px;
  }
  .affiliate-link-container {
    gap: 10px;
  }
  .affiliate-table th,
  .affiliate-table td {
    padding: 10px;
  }
}
