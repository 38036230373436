.caoverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 0;
  animation: fadeIn 0.3s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.caduscfn {
  background-color: var(--addarkbgcolor);
  color: white;
  padding: 20px;
  width: fit-content;
  max-height: 70%;
  overflow-y: auto;
  border-radius: 10px;
  text-align: start;
  opacity: 0;
  transform: scale(0.8);
  animation: slideIn 0.3s ease-in-out forwards;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.cafwhi {
  font-weight: 600;
}

.accanbut {
  padding: 10px;
  min-width: 48%;
  background-color: var(--adbasebgcolor);
  border: none;
  border-radius: 15px;
  color: white;
  font-weight: 600;
  cursor: pointer;
  transition: 0.4s;
}
.caactext {
  font-weight: 500;
}

.accanbut:hover {
  background-color: var(--adbasehvcolor);
  transition: 0.4s;
}

.cacanbut {
  margin-left: 20px;
  padding: 10px;
  min-width: 48%;
  font-weight: 600;
  background-color: transparent;
  border: 2px solid var(--adbasebgcolor);
  border-radius: 15px;
  color: white;
  cursor: pointer;
  transition: 0.4s;
}

.cacanbut:hover {
  background-color: var(--adbasehvcolor);
  transition: 0.4s;
}

@media (max-width: 800px) {
  .caduscfn {
    max-width: 90%;
  }
}
