:root {
  --sitecolor: #333;
}

@media (min-width: 800px) {
  .jcjgc {
    display: none !important;
    visibility: hidden !important;
  }
}

.jcjgc .icon,
.jcjgc .txt-mb {
  color: var(--sitecolor);
}

.jcjgc .dark-navigation {
  position: fixed;
  z-index: 1000;
  width: 100%;
  bottom: 0px;
  background: rgba(34, 34, 34, 0.5);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  color: #ffffff;
}

.jcjgc .navigation {
  position: fixed;
  width: 100%;
  z-index: 1000;
  bottom: 0px;
  background: rgba(234, 234, 234, 0.5);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  color: black;
}

.lognav-ul {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.lognav-li {
  padding: 10px 15px 0px 15px;
  margin-left: -16px;
}

.lognav-li .a-links {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
}

.lognav-li .a-links:hover .icon {
  color: var(--sitecolor);
  transition: 0.5s;
}

.jcjgc .dark-navigation .lognav-li .a-links:hover .txt-mb,
.jcjgc .dark-navigation .lognav-li.acv-navfj .a-links .txt-mb {
  color: white;
  transition: 0.5s;
}

.jcjgc .navigation .lognav-li .a-links:hover .txt-mb,
.jcjgc .navigation .lognav-li.acv-navfj .a-links .txt-mb {
  color: black;
  transition: 0.5s;
}

.lognav-li .a-links .icon {
  color: grey;
  font-size: 1.1em;
  transition: 0.5s;
}

.jcjgc .a-links .log-order {
  fill: var(--sitecolor);
  transition: 0.5s;
}

.jcjgc .a-links .log-order:hover {
  fill: var(--stbasehvcolor);
  transition: 0.5s;
}

.lognav-li.acv-navfj .a-links .icon {
  color: var(--sitecolor);
  transition: 0.5s;
}

.jcjgc .a-links .txt-mb {
  position: relative;
  font-weight: 700;
  font-size: 0.5em;
  top: 5px;
  color: grey;
  transition: 0.5s;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.lognav-li.acv-navfj .a-links .txt-mb {
  opacity: 1;
  z-index: 1;
}
