@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

.notifyCon {
  position: fixed;
  top: 20%;
  left: 50%;
  z-index: 10000;
  transform: translate(-50%, -50%);
  animation-name: drop-down-fade;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
  animation-fill-mode: forwards;
  width: 60%;
  animation-play-state: running;
}

#adnotify .notifyText {
  box-shadow: 0 4px 6px #290046;
  background-color: var(--adbasebgcolor);
  color: white;
}

#lgnotify .notifyText {
  box-shadow: 0 4px 6px var(--lightbkcolor);
  background-color: #ddd;
  color: black;
}

#dknotify .notifyText {
  box-shadow: 0 4px 6px var(--darkbkcolor);
  background-color: #242323;
  color: white;
}

.notifyText {
  padding: 10px 20px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  overflow: auto;
  border-radius: 12px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
}

.notifyText .icon {
  font-size: 2rem;
  margin-right: 10px;
}

.notifyCon.paused {
  animation-play-state: paused;
}

@keyframes drop-down-fade {
  0% {
    opacity: 0;
    top: 0%;
  }
  50% {
    opacity: 1;
    top: 20%;
  }
  100% {
    opacity: 0;
    top: 0%;
  }
}

@media (max-width: 800px) {
  .notifyCon {
    width: 80%;
  }
  .notifyText .icon {
    font-size: 1.5rem;
  }
  .notifyText {
    font-size: 0.9rem;
  }
}