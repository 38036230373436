.adcolorpickercon {
  position: relative;
}

.adcolorpicker {
  position: absolute;
  top: 10px;
  right: 0;
  z-index: 1000;
}
