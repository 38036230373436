[data-theme="dark"] {
  --themecolor: #f3f1f1;
}

[data-theme="light"] {
  --themecolor: #333;
}

.clcustom-checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  font-size: 16px;
  color: #333;
  transition: color 0.3s;
}

.clcustom-checkbox input[type="checkbox"] {
  display: none;
}

.clcustom-checkbox .clcheckmark {
  width: 24px;
  height: 24px;
  border: 2px solid var(--themecolor);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  transition: background-color 0.3s, border-color 0.3s, transform 0.3s;
  transform-style: preserve-3d;
}

.clcustom-checkbox .clcheckmark::before {
  content: "\2713";
  font-size: 16px;
  color: transparent;
  transition: color 0.3s, transform 0.3s;
}

.clcustom-checkbox input[type="checkbox"]:checked + .clcheckmark {
  background-color: rgb(17, 196, 17);
  border-color: rgb(17, 196, 17);
  transform: scale(1.1) rotateZ(360deg) rotateY(360deg);
}

.clcustom-checkbox input[type="checkbox"]:checked + .clcheckmark::before {
  color: #fff;
}

.clcustom-checkbox:hover {
  color: #666;
}

.clcustom-checkbox:hover .clcheckmark {
  border-color: #666;
  background-color: #f0f0f0;
  transform: scale(1.05);
}

.clcustom-checkbox input[type="checkbox"]:focus + .clcheckmark {
  box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.2);
  outline: none;
}

.clcustom-checkbox .clcheckmark,
.clcustom-checkbox input[type="checkbox"]:checked + .clcheckmark {
  transition: background-color 1.3s, border-color 1.3s, color 1.3s,
    transform 0.3s;
}
