.adorderstb {
  background-color: var(--adbasebgcolor);
  border-radius: 12px;
  min-width: 100%;
  overflow-x: auto;
  margin-bottom: 100px;
}

.adorderstable {
  width: 100%;
}

.adorderstablehead,
.adorderstabledata {
  padding: 20px;
}

.adorderstabledata {
  border-top: 1px solid var(--adbaseactcolor);
}

.adordersusername {
  text-decoration: none;
  color: var(--adtextbgcolor);
  transition: 0.5s;
}

.adordersusername:hover {
  text-decoration: underline;
  transition: 0.5s;
}

.adorderstdurl {
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.adordersurl {
  text-decoration: none;
  color: var(--adtextbgcolor);
}

.adordersselectedtext {
  color: var(--adtextbgcolor);
}

.adordersstatus {
  min-width: 300px;
}

.clprogresscon {
  width: 100%;
  height: 20px;
  padding: 2px 4px;
  transition: background 0.3s ease;
  border-radius: 5px;
  background: linear-gradient(195deg, rgb(245, 245, 248), rgb(255, 255, 255));
}

.greenprogress {
  background: linear-gradient(to top right, #4caf50, #00ec08, #00ec08);
  background: linear-gradient(to bottom right, #4caf50, #00ec08, #00ec08);
  border-radius: 5px;
  transition: background, width 0.3s ease;
  height: 100%;
  animation: green-progress 2s infinite linear;
}

@keyframes green-progress {
  0% {
    background: linear-gradient(to top right, #4caf50, #00ec08, #00ec08);
    background: linear-gradient(to bottom right, #4caf50, #00ec08, #00ec08);
  }
  50% {
    background: linear-gradient(
      to top right,
      #4caf50,
      #00ec08,
      #00b806
    ); /* Change the color here */
    background: linear-gradient(
      to bottom right,
      #4caf50,
      #00ec08,
      #00b806
    ); /* Change the color here */
  }
  100% {
    background: linear-gradient(to bottom right, #4caf50, #00ec08, #00ec08);
    background: linear-gradient(to top right, #4caf50, #00ec08, #00ec08);
  }
}

.redprogress {
  background: linear-gradient(
    to top right,
    red,
    rgb(226, 2, 2),
    rgb(197, 38, 38)
  );
  background: linear-gradient(
    to bottom right,
    red,
    rgb(226, 2, 2),
    rgb(197, 38, 38)
  );
  border-radius: 5px;
  transition: background, width 0.3s ease;
  height: 100%;
  animation: red-progress 2s infinite linear;
}

@keyframes red-progress {
  0% {
    background: linear-gradient(
      to top right,
      red,
      rgb(226, 2, 2),
      rgb(197, 38, 38)
    );
    background: linear-gradient(
      to bottom right,
      red,
      rgb(226, 2, 2),
      rgb(197, 38, 38)
    );
  }
  50% {
    background: linear-gradient(
      to top right,
      rgb(219, 2, 2),
      rgb(214, 0, 0),
      rgb(228, 28, 28)
    );
    background: linear-gradient(
      to bottom right,
      rgb(219, 2, 2),
      rgb(214, 0, 0),
      rgb(228, 28, 28)
    );
  }
  100% {
    background: linear-gradient(
      to bottom right,
      red,
      rgb(226, 2, 2),
      rgb(197, 38, 38)
    );
    background: linear-gradient(
      to top right,
      red,
      rgb(226, 2, 2),
      rgb(197, 38, 38)
    );
  }
}

#partialMeter,
#activeMeter {
  width: 100%;
  height: 30px;
  border: 4px solid #ddd;
}

#partialMeter::-webkit-meter-optimum-value {
  background-color: #ff5722;
}

#activeMeter::-webkit-meter-optimum-value {
  background-color: #4caf50;
}

.orderstablegreenstat {
  color: #4caf50;
  font-weight: 700;
  white-space: nowrap;
}

.orderstableredstat {
  white-space: nowrap;
  font-weight: 700;
  color: #ff5722;
}

.orderstablebluestat {
  white-space: nowrap;
  font-weight: 700;
  color: rgb(119, 130, 236);
}

.orderstableyellowstat {
  font-weight: 700;
  color: #ffeb3b;
}

.adordetbbstatmain {
  cursor: pointer;
}

.adordetbbstatmain:hover .adorderstabledescstatcon {
  display: block;
  transition: display 0.5s;
}

.adorderstabledescstatcon {
  position: absolute;
  display: none;
  transition: display 0.5s;
}

.adorderstabledescstat {
  position: relative;
  padding: 10px;
  transition: 0.5s;
  border-radius: 12px;
  -webkit-box-shadow: 1px 2px 7px 4px var(--addarkbgcolor);
  -moz-box-shadow: 1px 2px 7px 4px var(--addarkbgcolor);
  box-shadow: 1px 2px 7px 4px var(--addarkbgcolor);
  text-align: center;
  background-color: var(--adbasebgcolor);
}

.orderstablestatsicon {
  font-size: 20px;
  margin: 0px 10px;
}

.adorderstbserviceda {
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.adortbsvdata {
  cursor: pointer;
  white-space: nowrap;
  transition: 0.5s;
}

.adortbsvdata:hover {
  color: var(--adtextbgcolor);
  transition: 0.5s;
}

.admbservice {
  cursor: pointer;
  white-space: nowrap;
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: 0.5s;
}

.admbservice:hover {
  color: var(--adtextbgcolor);
  transition: 0.5s;
}

.admborderurl {
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-decoration: none;
  color: var(--adtextbgcolor);
}

.adorderservID {
  background-color: var(--addarkbgcolor);
  padding: 5px 10px;
  border-radius: 10px;
  margin-right: 20px;
}

.admbordersnav .adortballsel {
  right: 0;
}

.admbordersnav .adulsel {
  top: 100px;
}

.adortballsel {
  position: absolute;
}

.admbordersnav {
  padding: 20px;
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
  background-color: var(--adbasebgcolor);
  align-items: center;
  border-radius: 10px;
  justify-content: space-between;
  overflow: hidden;
}

.admborderscon {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.admborderdet {
  background-color: var(--adbasebgcolor);
  cursor: pointer;
  border-radius: 10px;
  transition: transform 0.5s ease;
}

.admborderdet:hover {
  transform: translateY(-10px);
}

.admborderdethead {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  gap: 20px;
  border-bottom: 1px solid var(--adbaseactcolor);
}

.admborderdetbody {
  padding: 20px;
  display: flex;
  overflow: hidden;
  align-items: center;
  max-width: 100%;
  justify-content: space-between;
  gap: 20px;
}

@media (max-width: 965px) {
  .adortballsel {
    right: 10px;
  }
}
