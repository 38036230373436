.rrdh {
  width: 100%;
  min-height: 100vh;
  background-color: var(--bglightcolor);
  overflow-y: auto;
}
.ndnuu {
  width: 100%;
  min-height: 100vh;
  background-color: var(--bgdarkcolor);
  overflow-y: auto;
}

.orders-nav {
  margin-left: 320px;
  margin-top: 100px;
  margin-right: 20px;
  margin-bottom: 50px;
  height: 100%;
}
.orderstaticon {
  font-size: 25px;
}

.ordersstatcon {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.orallorders,
.oractiveorders,
.orpartialorders,
.orcompletedorders,
.orpendingorders,
.orfailedorders,
.orcancelledorders {
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  text-decoration: none;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  color: black;
  transition: 0.5s;
  box-shadow: none;
  margin-bottom: 20px;
}

.orallorders:hover,
.oractiveorders:hover,
.orpartialorders:hover,
.orcompletedorders:hover,
.orpendingorders:hover,
.orfailedorders:hover,
.orcancelledorders:hover {
  background-color: rgb(243, 242, 242);
  transition: 0.5s;
}

.darkorallorders,
.darkoractiveorders,
.darkorpartialorders,
.darkorcompletedorders,
.darkorpendingorders,
.darkorfailedorders,
.darkorcancelledorders {
  background-color: black;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  text-decoration: none;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  color: white;
  transition: 0.5s;
  box-shadow: none;
  margin-bottom: 20px;
}

.darkorallorders:hover,
.darkoractiveorders:hover,
.darkorpartialorders:hover,
.darkorcompletedorders:hover,
.darkorpendingorders:hover,
.darkorfailedorders:hover,
.darkorcancelledorders:hover {
  background-color: rgb(7, 7, 7);
  transition: 0.5s;
}

.orallorders strong,
.oractiveorders strong,
.orpartialorders strong,
.orcompletedorders strong,
.orpendingorders strong,
.orfailedorders strong,
.orcancelledorders strong,
.darkorallorders strong,
.darkoractiveorders strong,
.darkorpartialorders strong,
.darkorcompletedorders strong,
.darkorpendingorders strong,
.darkorfailedorders strong,
.darkorcancelledorders strong {
  transition: 0.5s;
}

.ordersrouteactivecolor {
  color: var(--sttextbgcolor);
}

.orallorders:hover strong,
.oractiveorders:hover strong,
.orpartialorders:hover strong,
.orcompletedorders:hover strong,
.orpendingorders:hover strong,
.orfailedorders:hover strong,
.orcancelledorders:hover strong,
.darkorallorders:hover strong,
.darkoractiveorders:hover strong,
.darkorpartialorders:hover strong,
.darkorcompletedorders:hover strong,
.darkorpendingorders:hover strong,
.darkorfailedorders:hover strong,
.darkorcancelledorders:hover strong {
  color: var(--sitecolor);
  text-decoration: underline;
  transition: 0.5s;
}

.orstatsecon-red {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 4rem;
  margin-right: 40px;
  opacity: 1;
  background: linear-gradient(195deg, rgb(255, 61, 0), rgb(255, 99, 99));
  color: rgb(255, 255, 255);
  border-radius: 0.75rem;
  box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem,
    rgba(255, 99, 71, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
}

.orstatsecon-partial {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 4rem;
  margin-right: 40px;
  opacity: 1;
  background: linear-gradient(195deg, rgb(0, 128, 255), rgb(0, 191, 255));
  color: rgb(255, 255, 255);
  border-radius: 0.75rem;
  box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem,
    rgba(0, 191, 255, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
}

.orstatsecon-pending {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 4rem;
  margin-right: 40px;
  opacity: 1;
  background: linear-gradient(195deg, rgb(255, 255, 0), rgb(255, 215, 0));
  color: rgb(0, 0, 0);
  border-radius: 0.75rem;
  box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem,
    rgba(255, 215, 0, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
}

.orstatsecon-main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 4rem;
  margin-right: 40px;
  opacity: 1;
  background: linear-gradient(195deg, var(--sitecolor), var(--sitecolor));
  color: rgb(255, 255, 255);
  border-radius: 0.75rem;
  box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem,
    var(--sitecolor) 0rem 0.4375rem 0.625rem -0.3125rem;
}

.orstatsecon-active {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 4rem;
  margin-right: 40px;
  opacity: 1;
  background: linear-gradient(195deg, rgb(0, 128, 0), rgb(0, 255, 0));
  color: rgb(255, 255, 255);
  border-radius: 0.75rem;
  box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem,
    rgba(0, 255, 0, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
}

.orstatsecon-dred {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 4rem;
  margin-right: 40px;
  opacity: 1;
  background: linear-gradient(195deg, rgb(139, 0, 0), rgb(255, 0, 0));
  color: rgb(255, 255, 255);
  border-radius: 0.75rem;
  box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem,
    rgba(255, 0, 0, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
}

.orstatsecon-green {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 4rem;
  margin-right: 40px;
  opacity: 1;
  background: linear-gradient(195deg, rgb(34, 195, 115), rgb(45, 253, 139));
  color: rgb(255, 255, 255);
  border-radius: 0.75rem;
  box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem,
    rgba(28, 248, 119, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
}

.aduserstat {
  margin: 0px 0px 0px 10px;
  font-size: 1.5rem;
  line-height: 1.375;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.00735em;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  text-decoration: none;
  color: rgb(119, 119, 119);
}
.orders-status {
  background-color: white;
  border-radius: 15px;
  padding: 10px 10px 1px 0px;
  width: 100%;
  min-width: 745px;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 800px) {
  .rrdh,
  .ndnuu {
    font-size: var(--bodyfontsize);
  }
  .orders-nav {
    margin-left: 20px;
    margin-bottom: 100px;
  }
}
