.bft {
  height: 100dvh;
  background-color: var(--addarkbgcolor);
  display: flex;
  align-items: center;
  justify-content: center;
}

.admloading {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 9px solid;
  border-color: #b815ce #0000;
  animation: spinner-0tkp9a 1s infinite;
}

@keyframes spinner-0tkp9a {
  to {
    transform: rotate(0.5turn);
  }
}

@media (max-width: 800px) {
  .admloading {
    width: 36px;
    border: 5px solid;
    border-color: #b815ce #0000;
    height: 36px;
  }
  @keyframes spinner-0tkp9a {
    to {
      transform: rotate(0.5turn);
    }
  }
}
