.adintegration {
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: var(--addarkbgcolor);
  color: white;
}

.adintegrationmaincon {
  position: relative;
  margin-left: 360px;
  display: flex;
  flex-wrap: wrap;
  margin-right: 20px;
  top: 20px;
  gap: 20px;
}

.adintegrationhead {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--adbasebgcolor);
  padding: 10px 0px;
  border-radius: 10px;
}

.adintegrationbody {
  background-color: var(--adbasebgcolor);
  margin-top: 20px;
  border-radius: 10px;
}

.adintegrationhd {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.adintegrationbody .adintgclcon:not(:last-child) {
  border-bottom: 1px solid var(--adbaseactcolor);
}

.adintgclcon {
  padding: 20px;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.adintgclside {
  display: flex;
  align-items: center;
  border-radius: 15px;
}

.adintgclside button.adintgacside {
  background-color: var(--adbaseactcolor);
  border: none;
  color: white;
  padding: 5px;
  border-radius: 5px;
}

.adintgclside button {
  background-color: var(--adbasebgcolor);
  padding: 5px;
  border: none;
  border-radius: 5px;
  color: white;
}

.adintgclcon textarea{
  min-height: 200px;
}

@media (max-width: 800px) {
  .adintegration {
    height: 100%;
    font-size: var(--bodyfontsize);
  }
  .adintegration .adcontrolpanel {
    display: none;
  }
  .adintegrationmaincon {
    margin-left: 20px;
    margin-bottom: 100px;
    top: 0px;
    gap: 0px;
  }
}
