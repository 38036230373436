.sttopnavcon {
  position: absolute;
  width: calc(100% - 290px);
  margin-left: 290px;
  background-color: white;
  z-index: 1000;
}

.dark-sttopnavcon {
  z-index: 1000;
  position: absolute;
  width: calc(100% - 290px);
  margin-left: 290px;
  background-color: black;
}

.sttopnav {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 0px;
}

.stuserbalancebtn {
  color: white;
}

.dark-sttopnavcon .stuserbalancebtn,
.stuserbalancebtn {
  display: flex;
  padding: 13px 20px;
  border: 2px solid var(--stbaseactcolor);
  align-items: center;
  position: relative;
  border-radius: 35px;
  min-width: 200px;
  cursor: pointer;
  justify-content: space-between;
}

.dttopnav .stuserbalancebtn,
.dttopnav .stuserdrop,
.dttopnav .stchangecurrency,
.dark-sttopnavcon .staddfunds {
  background-color: var(--bgdarkcolor);
  color: white;
}

.stuserdrop,
.stuserbalancebtn,
.topnav .stchangecurrency,
.staddfunds {
  background-color: var(--bglightcolor);
  color: black;
}

.stuserbalancebtn .stbalicon {
  font-size: 1.3rem;
  color: var(--stbaseactcolor);
  margin-right: 10px;
}

.stuserbalancebtn .stbaldownicon {
  font-size: 1.1rem;
  margin-left: 10px;
  color: var(--stbaseactcolor);
}

.staddfunds {
  border-radius: 35px;
  border: 2px solid var(--stbaseactcolor);
}

.sttopnavcon .staddfunds:hover {
  background: linear-gradient(
    to bottom right,
    white,
    var(--bglightcolor),
    var(--bglightcolor),
    white
  );
}

.dark-sttopnavcon .staddfunds:hover {
  background: linear-gradient(
    to bottom right,
    black,
    var(--bgdarkcolor),
    var(--bgdarkcolor),
    black
  );
}

.staddfunds a {
  text-decoration: none;
  padding: 15px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--stbaseactcolor);
  font-weight: 600;
}

.staddfunds a .staddfundicon {
  font-size: calc(1em + 0.2rem);
  color: var(--stbaseactcolor);
  margin-right: 10px;
}

.stdrpadunicon {
  color: white;
  margin-right: 10px;
}

.stlogin a,
.stneworder a {
  text-decoration: none;
  padding: 15px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 600;
  border-radius: 35px;
  background: linear-gradient(
    to top left,
    var(--stbaseactcolor),
    var(--stbasehvcolor),
    var(--stbasehvcolor),
    var(--stbaseactcolor)
  );
}

.stlogin a:hover,
.stneworder a:hover {
  background: linear-gradient(
    to top right,
    var(--stbasehvcolor),
    var(--stbasebgcolor),
    var(--stbasebgcolor),
    var(--stbasehvcolor)
  );
}

.stlogin a .stloicon,
.stneworder a .stloicon {
  transition: 0.5s;
  color: white;
  margin-right: 10px;
  font-size: calc(1em + 0.5rem);
}

.stuserdropft {
  position: relative;
  z-index: 1000;
}

.stuserdrop {
  position: absolute;
  top: 20px;
  padding: 10px;
  border-radius: 14px;
  border: 1px solid var(--stbaseactcolor);
  -webkit-box-shadow: -5px 7.5px 10px -0.5px var(--stbaseactcolor);
  -moz-box-shadow: -5px 7.5px 10px -0.5px var(--stbaseactcolor);
  box-shadow: -5px 7.5px 10px -0.5px var(--stbaseactcolor);
}

.stuserdropbtns {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.stuserdropbtns button .stuserdrpicon {
  font-size: calc(1em + 0.5rem);
  transition: 0.5s;
  margin-right: 10px;
}

.stuserdropbtns button:nth-child(2) {
  margin-top: 5px;
}

.stuserdropbtns button {
  border: none;
  white-space: nowrap;
  font-size: calc(1em + 0.2rem);
  display: flex;
  justify-content: space-between;
  transition: 0.5s;
  align-items: center;
  color: inherit;
  border-radius: 13px;
  font-weight: 600;
  background-color: transparent;
  padding: 20px;
}

.stuserdropbtns a {
  white-space: nowrap;
  display: none;
  justify-content: center;
  transition: 0.5s;
  align-items: center;
  text-decoration: none;
  color: white;
  border-radius: 13px;
  font-weight: 600;
  background-color: var(--sitecolor);
}

.stuserdropbtns button:hover .stuserdrpicon {
  color: var(--stbaseactcolor);
  transition: 0.5s;
}

.stchangecurrencycon {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9000;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
}

.stchangecurrency {
  border-radius: 20px;
  padding: 20px;
  max-width: 80%;
  overflow-y: auto;
  box-sizing: border-box;
  width: 500px;
  max-height: 80%;
}

.dark-sttopnavcon .stcurrencies {
  background-color: rgb(7, 7, 7);
}

.sttopnavcon .stcurrencies {
  background-color: rgb(253, 253, 253);
}

.stchangecurrencyhd {
  font-size: calc(1em + 1rem);
  font-weight: 600;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
      91deg,
      var(--stbaseactcolor),
      var(--stbasehvcolor),
      var(--stbasehvcolor),
      var(--stbaseactcolor)
    )
    text;
}

.stcurrencies {
  margin-top: 20px;
  width: 100%;
  border-radius: 20px;
}

.stbaluseramount {
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.stcurrency {
  padding: 20px;
  font-size: calc(1rem + 0.2rem);
  transition: 0.5s;
  cursor: pointer;
}

.stcurrency:hover {
  color: var(--stbaseactcolor);
  transition: 0.5s;
}

.stcurrency:not(:last-child),
.sttransactiontr:not(:last-child) {
  border-bottom: 1px solid var(--stbasebgcolor);
}

.sttransaction {
  padding: 20px;
  font-size: calc(1rem + 0.2rem);
}

.sttransactionamon {
  padding: 20px;
  font-size: calc(1rem + 0.2rem);
}

.menuhd {
  display: none;
  background-color: white;
  visibility: hidden;
  height: 70px;
  position: absolute;
  border-bottom: 1px solid #ddd;
  top: 0px;
  width: 100%;
  justify-content: space-between;
  padding: 10px;
  z-index: 1000;
  align-items: center;
}

.menuhd img,
.darkmenuhd img {
  width: 60px;
  height: 60px;
}

.darkmenuhd {
  background-color: var(--bgdarkcolor);
  border-bottom: 1px solid #181818;
  color: white;
  display: none;
  visibility: hidden;
  height: 70px;
  position: absolute;
  top: 0px;
  width: 100%;
  justify-content: space-between;
  padding: 10px;
  z-index: 1000;
  align-items: center;
}

@media (max-width: 800px) {
  .stchangecurrency {
    max-width: 90%;
    max-height: 90%;
  }
  .menuhd,
  .darkmenuhd {
    display: flex;
    visibility: visible;
  }
  .sttopnavcon,
  .dark-sttopnavcon {
    display: none;
    visibility: hidden;
  }
  .stuserdropbtns a {
    display: flex;
  }
  .stcurrency,
  .sttransaction,
  .sttransactionamon {
    font-size: 0.7rem;
  }
  .stuserdrop {
    right: 5px;
  }
  .sttransaction {
    max-width: 50px;
  }
  .sttransactionamon {
    text-overflow: ellipsis;
    max-width: 200px;
    overflow: hidden;
  }
  .dark-sttopnavcon .stuserbalancebtn,
  .stuserbalancebtn {
    min-width: 100px;
  }
  .stuserdropbtns button,
  .stuserdropbtns a {
    font-size: 0.7rem;
    padding: 0.7rem;
  }
  .stuserbalancebtn {
    padding: 8px 20px;
  }
}
