.ProseMirror {
  padding: 16px;
  border-radius: 10px;
  min-height: 300px;
}

.cltoolbar {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid var(--stbasebgcolor);
  margin-bottom: 10px;
}

.cltoolbar button {
  background: none;
  color: inherit;
  border: none;
  transition: 0.3s;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.cltoolbar button:hover {
  transition: 0.3s;
  background-color: var(--stbaseactcolor);
  color: white;
  font-weight: bold;
}

.cltoolbar button.iscl-active {
  background-color: var(--stbaseactcolor);
  transition: 0.3s;
  color: white;
  font-weight: bold;
}

.clrteupimg,
.clrteuplink {
  position: relative;
}

.clrteupimgcon,
.clrteuplinkcon {
  position: absolute;
  border: 1px solid var(--stbasebgcolor);
  border-radius: 10px;
  padding: 16px;
  width: 250px;
  top: 40px;
  right: 0px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.clrteupimgcon h4,
.clrteuplinkcon h4 {
  margin: 0 0 10px 0;
  font-size: 16px;
  color: var(--stbaseactcolor);
}

.clrteupimgcon input[type="file"] {
  width: 100%;
  padding: 8px;
  border: 1px solid var(--stbasebgcolor);
  border-radius: 5px;
  margin-bottom: 10px;
}

.clrteupimgcon input[type="text"],
.clrteuplinkcon input[type="text"] {
  width: 100%;
  padding: 10px;
  border: none;
  outline: 1px solid var(--stbasebgcolor);
  border-radius: 10px;
  background-color: transparent;
  border-radius: 5px;
  color: inherit;
  margin-bottom: 10px;
}

.clrteupimgcon input[type="text"]:focus,
.clrteuplinkcon input[type="text"]:focus {
  outline: 1px solid var(--stbasebgcolor);
}

.clrteupimgcon input[type="file"]::file-selector-button {
  background-color: var(--stbaseactcolor);
  color: white;
  border: none;
  padding: 8px 16px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.clrteupimgcon input[type="file"]::file-selector-button:hover {
  background-color: var(--stbasehvcolor);
  transition: background-color 0.3s;
}

.clrteupimgcon button,
.clrteuplinkcon button {
  width: 100%;
  padding: 8px;
  background-color: var(--stbaseactcolor);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

.clrteupimgcon button:hover,
.clrteuplinkcon button:hover {
  background-color: var(--stbasehvcolor);
}

.clrteupimgcon .clrtealturl,
.clrteuplinkcon .clrtealturl {
  margin-top: 10px;
  text-align: center;
  color: var(--stbaseactcolor);
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
}

.cltoolbarbtnicon {
  font-size: 1.3rem;
}

@media (max-width: 800px) {
  .clrteuplinkcon {
    right: -70px;
  }
}
