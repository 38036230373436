.details-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.details-overlay-con {
  background-color: var(--addarkbgcolor);
  color: white;
  padding: 20px;
  width: 600px;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  border-radius: 10px;
}

.detailsHeadtext {
  font-weight: bold;
  color: var(--adtextbgcolor);
}

.detailsBodyText {
  padding: 15px;
  border: 1px solid var(--adbasebgcolor);
  border-radius: 15px;
  font-family: inherit;
  white-space: pre-wrap;
  margin-top: 20px;
}
