.adpagination {
  padding: 30px 0px;
  display: flex;
  list-style-type: none;
  cursor: pointer;
  width: 100%;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
}

.adpagination a {
  color: white;
}

.ad-prv-item {
  color: white;
  border-radius: 14px;
  padding: 14px;
  background-color: var(--adbasebgcolor);
  position: absolute;
  left: 0px;
}

.ad-next-item {
  color: white;
  border-radius: 14px;
  padding: 14px;
  background-color: var(--adbaseactcolor);
  position: absolute;
  right: 0px;
}

.addisabled-pag {
  background-color: var(--adbaseactcolor);
  color: white;
  border-radius: 14px;
  padding: 14px;
}

.adnext-link,
.adprv-link {
  text-decoration: none !important;
}

.ad-page-item {
  background-color: inherit;
  margin-right: 5px;
  border-radius: 10px;
  color: white;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 17px;
  border: 1px solid rgb(27, 27, 27);
  line-height: 1.17;
  transition: 0.5s;
}

.ad-page-item:hover {
  background-color: var(--adbasehvcolor);
  color: white;
  transition: 0.5s;
}

.acvad {
  color: #fff;
  background-color: var(--sitecolor);
}

@media (max-width: 800px) {
  .pagination {
    margin-bottom: 100px;
  }
}
