.not-found{
    margin-top: 30px;
    background-color: var(--adbasebgcolor);
    color: white;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 20px;
    text-align: center;
}

.kvkbf{
    width: 60px;
    height: 60px;
}

@media(max-width:800px){   
    .not-found{
        min-width: 100%;
    }
    .dark-not-found{
        min-width: 100%;
    }
}