.edseroverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 0;
  animation: fadeIn 0.3s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.editservcon {
  background-color: var(--addarkbgcolor);
  color: white;
  padding: 20px;
  width: 600px;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  border-radius: 10px;
  opacity: 0;
  transform: scale(0.8);
  animation: slideIn 0.3s ease-in-out forwards;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.edserheadtext {
  font-style: italic;
  text-transform: uppercase;
  color: var(--adtextbgcolor);
}

.editserprovider {
  background-color: var(--adbasebgcolor);
  padding: 20px;
  border-radius: 20px;
}

.editserproviderinp {
  display: flex;
}

#editprovselect,
#editservprov {
  width: 100%;
  padding: 20px;
  border: none;
  border-radius: 10px;
  outline: 1px solid var(--adbasebgcolor);
  color: white;
  background-color: transparent;
}

#editprovselect:focus,
#editservprov:focus {
  outline: 1px solid var(--adbaseactcolor);
}

#editservprov {
  margin-left: 10px;
}

.adeditservdesc {
  background-color: var(--adbasebgcolor);
  padding: 20px;
  width: 100%;
  border-radius: 20px;
}

#editservicedescription {
  width: 100%;
  padding: 20px;
  height: 200px;
  border-radius: 20px;
  border: 1px solid var(--adbasehvcolor);
  outline: none;
  color: white;
  background-color: transparent;
}

.adsyncminmax {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.adeditproviderprice,
.adeditactualprice {
  display: flex;
  justify-content: space-between;
}

.adeditactualprice {
  margin-top: 20px;
}

.adeditproviderprice span:nth-child(1),
.adeditactualprice span:nth-child(1) {
  white-space: nowrap;
  font-weight: 600;
}

.adeditproviderprice span:nth-child(2),
.adeditactualprice span:nth-child(2) {
  font-weight: 800;
  font-size: 17px;
  font-style: italic;
}

.adeditservprice {
  margin-top: 50px;
}

.adeditpercentCon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.adeditpercentCon span {
  font-weight: 600;
}

#servicePercentage {
  padding: 10px 20px;
  border-radius: 20px;
  border: 1px solid var(--adbasehvcolor);
  font-weight: 600;
  outline: none;
  color: white;
  background-color: transparent;
}

#servicePercentage::-webkit-inner-spin-button,
#servicePercentage::-webkit-outer-spin-button,
#adrefillDays::-webkit-inner-spin-button,
#adrefillDays::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.adeditservrefill {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.adeditservrefill span {
  font-weight: 600;
  display: flex;
  align-items: center;
}

.adrefillicon {
  width: 25px;
  height: 25px;
  object-fit: contain;
  margin-right: 10px;
}

.adserrefdays {
  margin-top: 40px;
}

#adrefillDays {
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid var(--adbasehvcolor);
  font-weight: 600;
  outline: none;
  color: white;
  margin-top: 10px;
  width: 100%;
  background-color: transparent;
}

.adeditsavebtn {
  border: none;
  border-radius: 10px;
  width: 100%;
  color: white;
  background-color: var(--adbaseactcolor);
  transition: 0.5s;
  padding: 20px;
  margin-top: 20px;
  font-weight: 600;
}

.adeditsavebtn:hover {
  background-color: var(--adbasehvcolor);
  transition: 0.5s;
}

.adpercentincon {
  position: relative;
}

.percnticon {
  position: absolute;
  right: 5%;
  top: 19%;
}

@media (max-width: 800px) {
  .adeditproviderprice span:nth-child(2),
  .adeditactualprice span:nth-child(2) {
    font-size: calc(var(--bodyfontsize) + 0.1rem);
  }
}
