.adpaymeths {
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: var(--addarkbgcolor);
  color: white;
}

.adpaymethmaincon {
  position: relative;
  margin-left: 360px;
  display: flex;
  flex-wrap: wrap;
  margin-right: 20px;
  top: 20px;
  gap: 20px;
}

.adpaymethshead {
  background-color: var(--adbasebgcolor);
  display: flex;
  margin-bottom: 20px;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0px;
  border-radius: 10px;
  width: 100%;
}

.adpaymethshead span {
  font-size: inherit;
  font-weight: 600;
}

.adpaymethstable {
  width: 100%;
  overflow-x: auto;
  border-radius: 10px;
  margin: auto;
  background-color: var(--adbasebgcolor);
}

.adpaymethstable table {
  width: 100%;
}

.adoaymethstbtd {
  padding: 20px;
}

.adoaymethstbtd span{
  font-weight: 600;
}

.adoaymethstbtr:not(:last-child) {
  border-bottom: 1px solid var(--adbaseactcolor);
}

.adpaymethsactbtnwi {
  max-width: 80px;
  white-space: nowrap;
}

.adpaymentacticon {
  margin-right: 10px;
  font-size: 24px;
}

.adselpaymmetod,
.adpaymmethfill {
  margin-top: 20px;
}

.adselpaymmetod h6 {
  margin-bottom: 5px;
  font-weight: 600;
}

@media (max-width: 945px) {
  .adprovtbtdminwdtg {
    display: inline-block;
  }
}

@media (max-width: 500px) {
  .adprovwrapcon {
    display: inline-block;
  }
}

@media (max-width: 800px) {
  .adpaymeths {
    height: 100dvh;
    font-size: var(--bodyfontsize);
  }
  .adpaymeths .adcontrolpanel {
    display: none;
  }
  .adpaymethmaincon {
    margin-left: 20px;
    top: 0px;
    gap: 0px;
    margin-bottom: 100px;
  }
}
