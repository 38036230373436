.adcategoriestable {
  background-color: var(--adbasebgcolor);
  border-radius: 12px;
  min-width: 100%;
  overflow: hidden;
  margin-bottom: 100px;
}

.adcategoriestable table {
  width: 100%;
}

.adcathead,
.adcatdata {
  padding: 20px;
}

.adcatdata {
  border-top: 1px solid var(--adbaseactcolor);
}

.adcatmaxwbtn {
  max-width: 50px;
  white-space: nowrap;
}

.adcatdeletecat {
  cursor: pointer;
  padding: 8px 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  background-color: transparent;
  color: red;
  border: 1px solid var(--adbasehvcolor);
  transition: 0.4s;
}

.adcatdeletecat:hover {
  background-color: var(--adbaseactcolor);
  transition: 0.4s;
}

.adcatdatname {
  color: var(--adtextbgcolor);
  cursor: pointer;
}

.adcatdatnamem {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
