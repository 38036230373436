.whatsapp {
  position: fixed;
  bottom: 20px;
  z-index: 2000;
  height: 50px;
  padding: 10px;
  width: 50px;
  border-radius: 50%;
  color: white;
  background-color: rgb(12, 205, 12);
  transition: background-color 1s;
}

.instagram {
  position: fixed;
  bottom: 20px;
  z-index: 2000;
  height: 50px;
  padding: 10px;
  color: white;
  width: 50px;
  border-radius: 50%;
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  transition: background-color 1s;
}

.whatsappcon {
  height: 50px;
  padding: 10px;
  width: 50px;
  border-radius: 50%;
  color: white;
  background-color: rgb(12, 205, 12);
  transition: background-color 1s;
}

.whatsapp:hover {
  background-color: rgb(12, 188, 12);
  transition: background-color 1s;
}

.otherintgcon {
  height: 50px;
  padding: 10px;
  color: white;
  width: 50px;
  border-radius: 50%;
  background: linear-gradient(
    to top right,
    rgb(239, 239, 248),
    rgb(58, 58, 238),
    rgb(39, 39, 230),
    rgb(232, 232, 238)
  );
}

.whatsapp-icon,
.otherintg {
  font-size: 30px;
}

.adintghead {
  display: flex;
  align-items: center;
}

.adintghead span {
  margin-left: 10px;
}

.instagramcon {
  height: 50px;
  padding: 10px;
  width: 50px;
  border-radius: 50%;
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  transition: background-color 1s;
}

.instagram-icon {
  font-size: 30px;
}

@media (max-width: 800px) {
  .whatsapp,
  .instagram {
    bottom: 90px;
  }
}
