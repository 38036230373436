.dark-accouunt {
  background-color: var(--bgdarkcolor);
  min-height: 100vh;
  overflow-y: auto;
  width: 100%;
  --themetextcolor: black;
  --themeopptextcolor: white;
  --themecolor: #333;
}

.accouunt {
  background-color: var(--bglightcolor);
  min-height: 100vh;
  overflow-y: auto;
  --themecolor: #ddd;
  --themeopptextcolor: black;
  --themetextcolor: white;
}

.accut {
  margin-left: 320px;
  margin-top: 100px;
}

.accouunt .claccmaincon {
  width: auto;
  margin-right: 20px;
  height: 100%;
  background-color: white;
  color: #000;
  border-radius: 10px;
  margin-bottom: 20px;
}

.dark-accouunt .claccmaincon {
  width: auto;
  margin-right: 20px;
  height: 100%;
  background-color: black;
  color: white;
  border-radius: 10px;
  margin-bottom: 20px;
}

.account-details {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.acc-form {
  display: grid;
  flex-grow: 1;
  max-width: 800px;
  width: 90%;
  gap: 20px;
}

.dark-accouunt .claccusername {
  background-color: var(--bgdarkcolor);
}

.accouunt .claccusername {
  background-color: var(--bglightcolor);
}

.claccusername,
.claccoldpasswd,
.claccnewpasswd,
.claccemail {
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  color: inherit;
  background-color: inherit;
  border: none;
  outline: 2px solid var(--themecolor);
}

.claccusername:focus,
.claccoldpasswd:focus,
.claccnewpasswd:focus,
.claccemail:focus {
  outline: 2px solid var(--themecolor);
  box-shadow: 0 0 0 0 var(--themetextcolor);
}

.claccusername:-webkit-autofill:focus,
.claccnewpasswd:-webkit-autofill:focus,
.claccemail:-webkit-autofill:focus,
.claccoldpasswd:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px var(--themetextcolor) inset !important;
  -webkit-text-fill-color: var(--themeopptextcolor) !important;
}

.claccusername:-webkit-autofill,
.claccnewpasswd:-webkit-autofill,
.claccemail:-webkit-autofill,
.claccoldpasswd:-webkit-autofill {
  box-shadow: 0 0 0 1000px var(--themetextcolor) inset !important;
  -webkit-text-fill-color: var(--themeopptextcolor) !important;
}

.changePassword {
  min-width: 10px;
  padding: 10px 3px;
  background-color: var(--sitecolor);
  color: white;
  font-weight: 600;
  border-radius: 10px;
  border: none;
  transition: 0.5s;
}

.claccpassdiv {
  position: relative;
  display: flex;
  align-items: center;
}

.claccpasswicon {
  cursor: pointer;
  position: absolute;
  font-size: 1.5rem;
  right: 10px;
  animation: fadeIn 1s;
}

.changePassword:hover {
  background-color: var(--stbasehvcolor);
  transition: 0.5s;
}

.acc-email {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.c-email {
  min-width: 10px;
  padding: 10px 3px;
  background-color: var(--sitecolor);
  color: white;
  border-radius: 10px;
  font-weight: 600;
  border: none;
  transition: 0.5s;
}

.c-email:hover {
  background-color: var(--stbasehvcolor);
  transition: 0.5s;
}

.acc-preferences {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

#themeSelect {
  max-width: 800px;
  width: 90%;
}

.acc-logout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.lg-out {
  max-width: 800px;
  width: 90%;
  background-color: initial;
  padding: 8px 3px;
  font-weight: 600;
  color: var(--sitecolor);
  border-radius: 10px;
  border: 2px solid var(--sitecolor);
}

.accggenapi {
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid var(--stbasehvcolor);
  padding: 10px;
  display: flex;
  max-width: 800px;
  width: 90%;
  justify-content: space-between;
  align-items: center;
  transition: 0.5s;
}

.accggenapi:hover {
  color: var(--sttextbgcolor);
  transition: 0.5s;
}

.accouunt .accapiiicon {
  color: black;
}

.dark-accouunt .accapiiicon {
  color: white;
}

.accapiiicon:hover {
  color: var(--sttextbgcolor);
}

#mylightborder {
  border-top: 2px solid var(--bglightcolor);
}

#mydarkborder {
  border-top: 2px solid var(--bgdarkcolor);
}

.option-icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

@media (max-width: 800px) {
  .accouunt,
  .dark-accouunt {
    font-size: var(--bodyfontsize);
  }
  .accut {
    margin-left: 20px;
  }
  .accouunt .claccmaincon,
  .dark-accouunt .claccmaincon {
    margin-bottom: 130px;
  }
  #themeSelect {
    margin-bottom: 100px;
  }
}
