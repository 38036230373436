.ortboverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 0;
  animation: fadeIn 0.3s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.ortbcfn {
  background-color: var(--addarkbgcolor);
  color: white;
  padding: 20px;
  width: fit-content;
  max-width: 90%;
  max-height: 70%;
  overflow-y: auto;
  border-radius: 10px;
  opacity: 0;
  transform: scale(0.8);
  animation: slideIn 0.3s ease-in-out forwards;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
.ortbactionsbtn {
  padding: 12px;
  border-radius: 20px;
  background-color: var(--adbaseactcolor);
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.adsyncorder {
  padding: 7px 14px;
  display: flex;
  justify-content: space-between;
}

.ortbbtns {
  color: white;
  text-align: start;
  border: none;
  border-radius: 10px;
  padding: 7px 14px;
  font-weight: 600;
  background-color: transparent;
}

.ortbcancelorder {
  color: rgb(219, 7, 7);
  text-align: start;
  border: none;
  border-radius: 10px;
  padding: 7px 14px;
  font-weight: 600;
  background-color: transparent;
}

.ortbcomments {
  background: linear-gradient(
    to top right,
    var(--addarkbgcolor),
    var(--adtextbgcolor)
  );
  -webkit-text-fill-color: transparent;
  background-clip: text;
  white-space: nowrap;
  font-size: 1.2rem;
  cursor: pointer;
}

.ortbcancelorder:hover {
  background-color: var(--adbasehvcolor);
}

.ortbbtns:hover {
  background-color: var(--adbasehvcolor);
}

.ortbbtns:hover .ortbbtnsicon {
  color: var(--addarkbgcolor);
}

.ortbbtnsicon {
  font-size: 25px;
  margin-right: 10px;
}

.ortbsseldet {
  margin-bottom: 20px;
}

.ordettable {
  width: 100%;
}

.ortbdeturl {
  max-width: 50px;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--adtextbgcolor);
}

.ortbservdet {
  max-width: 250px;
  margin-bottom: 20px;
}

.ortbdet {
  padding: 12px;
  border-radius: 20px;
  background-color: var(--adbaseactcolor);
}

.ordettable tr:not(:last-child) .ortbdetdata {
  border-bottom: 1px solid var(--addarkbgcolor);
}

.ortbdetdata {
  padding: 12px;
}

.ortbdetdata a {
  color: var(--adtextbgcolor);
}

.ortbselcancel {
  display: block;
  border: none;
  background-color: var(--adbasebgcolor);
  color: white;
  transition: 0.5s;
  font-weight: 600;
  padding: 7px 14px;
  border-radius: 15px;
  margin: 10px auto;
  width: 70%;
}

.ortbselcancel:hover {
  background-color: var(--adbaseactcolor);
  transition: 0.5s;
}

.ortbedof,
.ortbedstatus {
  color: var(--adtextbgcolor);
}

.partialcon form,
.startcountcon form,
.editlinkcon form {
  display: flex;
  flex-direction: column;
}

#partialAmountInput,
#editLinkInput,
#startCountInput {
  width: 100%;
  margin: 10px 0px;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid var(--adbasehvcolor);
  outline: none;
  color: white;
  background-color: transparent;
}

#partialAmountInput:focus,
#editLinkInput:focus,
#startCountInput:focus {
  border-color: var(--adbasehvcolor);
  outline: 0;
  box-shadow: 0 0 0 0.25rem var(--adbasebgcolor);
}

#partialAmountInput:-webkit-autofill:focus,
#editLinkInput:-webkit-autofill:focus,
#startCountInput:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px var(--adbasebgcolor) inset !important;
}

#partialAmountInput:-webkit-autofill,
#editLinkInput:-webkit-autofill,
#startCountInput:-webkit-autofill {
  box-shadow: 0 0 0 1000px var(--adbasebgcolor) inset !important;
}

#partialAmountInput::-webkit-inner-spin-button,
#partialAmountInput::-webkit-outer-spin-button,
#startCountInput::-webkit-inner-spin-button,
#startCountInput::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.ortbbtnupdate {
  font-weight: 600;
  margin-bottom: 8px;
  min-width: 100%;
  padding: 13px;
  background-color: var(--adbasebgcolor);
  border: none;
  border-radius: 12px;
  color: white;
  transition: 0.6s;
}

.ortbedderrsg {
  color: red;
}

.ortbbtnupdate:hover {
  transition: 0.6s;
  background-color: var(--adbaseactcolor);
}

.orachead {
  margin-bottom: 14px;
  font-style: italic;
  font-weight: 600;
  color: var(--adtextbgcolor);
}

.adsyncedorderprovider {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.adsyncordprvimg {
  width: 150px;
  height: 150px;
}

.adsyncordprovtxt {
  color: var(--adtextbgcolor);
  font-weight: 700;
}
