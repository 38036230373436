.adorders {
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: var(--addarkbgcolor);
  color: white;
}

.adordersmaincon {
  position: relative;
  margin-left: 360px;
  margin-right: 20px;
  margin-bottom: 30px;
  top: 20px;
}

.adordersstatcon {
  background-color: var(--adbasebgcolor);
  padding: 20px;
  border-radius: 20px;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  opacity: 1;
  color: white;
  transition: 0.5s;
  box-shadow: none;
  margin-bottom: 20px;
}

.adordersstatcon:hover {
  background-color: var(--adbaseactcolor);
  transition: 0.5s;
}

.adordersrouteactivecolor {
  color: var(--adtextbgcolor);
}

.adsearchorders {
  background-color: var(--adbasebgcolor);
  margin-bottom: 20px;
  margin-left: auto;
  color: white;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 10px;
  width: fit-content;
}

.adorderssearchbtn {
  background-color: transparent;
  border: none;
  transition: 0.5s;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.adorderssearchbtn .orsearIcon,
.adorderssearchbtn span {
  transition: 0.5s;
}

.adorderssearchbtn:hover .orsearIcon {
  color: var(--adbasehvcolor);
  transition: 0.5s;
}

.adorderssearchbtn:hover span {
  color: var(--adtextbgcolor);
  transition: 0.5s;
}

#adordersearchfield {
  width: 100%;
  border-radius: 20px;
  padding: 10px 20px;
  color: white;
  border: 1px solid var(--adbaseactcolor);
  background-color: transparent;
  height: auto;
}

#adordersearchfield:focus {
  border-color: var(--adbaseactcolor);
  outline: 0;
  box-shadow: 0 0 0 0.25rem var(--adbasehvcolor);
}

#adordersearchfield:-webkit-autofill {
  box-shadow: 0 0 0 1000px var(--adbasebgcolor) inset !important;
}

#adordersearchfield:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px var(--adbasebgcolor) inset !important;
}

.adorcancelsearch {
  color: inherit;
  font-size: 25px;
  top: 15%;
  cursor: pointer;
  position: absolute;
  right: 5%;
}

.adorsearIcon {
  font-size: 25px;
}

.adorstatsecon-main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 4rem;
  margin-right: 40px;
  opacity: 1;
  background: linear-gradient(
    195deg,
    var(--addarkbgcolor),
    var(--adbaseactcolor)
  );
  color: rgb(255, 255, 255);
  border-radius: 0.75rem;
  box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem,
    var(--addarkbgcolor) 0rem 0.4375rem 0.625rem -0.3125rem;
}

@media (max-width: 800px) {
  .adorders {
    height: 100%;
    font-size: var(--bodyfontsize);
  }
  .adordersmaincon {
    margin-left: 20px;
    margin-bottom: 100px;
  }
}
