.adusers {
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: var(--addarkbgcolor);
  color: white;
}

.aduserscon {
  position: relative;
  margin-left: 360px;
  margin-right: 20px;
  top: 20px;
}

.aduserstaticon {
  font-size: 25px;
}

.adusersstatcon {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.adtotalusers,
.adactiveusers,
.adbannedusers {
  background-color: var(--adbasebgcolor);
  padding: 20px;
  border-radius: 20px;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  text-decoration: none;
  color: white;
  transition: 0.5s;
  box-shadow: none;
  margin-bottom: 20px;
}

.adtotalusers:hover,
.adactiveusers:hover,
.adbannedusers:hover {
  background-color: var(--adbaseactcolor);
  transition: 0.5s;
}

.adtotalusers strong,
.adactiveusers strong,
.adbannedusers strong {
  transition: 0.5s;
}

.adtotalusers:hover strong,
.adactiveusers:hover strong,
.adbannedusers:hover strong {
  color: var(--adbasehvcolor);
  transition: 0.5s;
}

.activeusersroute strong {
  color: var(--adtextbgcolor);
  transition: 0.5s;
}

.adstatsecon-red {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 4rem;
  margin-right: 40px;
  opacity: 1;
  background: linear-gradient(195deg, rgb(255, 61, 0), rgb(255, 99, 99));
  color: rgb(255, 255, 255);
  border-radius: 0.75rem;
  box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem,
    rgba(255, 99, 71, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
}

.adstatsecon-violet {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 4rem;
  margin-right: 40px;
  opacity: 1;
  background: linear-gradient(195deg, rgb(148, 87, 235), rgb(94, 53, 177));
  color: rgb(255, 255, 255);
  border-radius: 0.75rem;
  box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem,
    rgba(107, 87, 214, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
}

.adstatsecon-green {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 4rem;
  margin-right: 40px;
  opacity: 1;
  background: linear-gradient(195deg, rgb(34, 193, 195), rgb(253, 187, 45));
  color: rgb(255, 255, 255);
  border-radius: 0.75rem;
  box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem,
    rgba(253, 187, 45, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
}

.aduserstat {
  margin: 0px 0px 0px 10px;
  font-size: 1.5rem;
  line-height: 1.375;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.00735em;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  text-decoration: none;
  color: rgb(119, 119, 119);
}

.adusertb {
  margin-top: 20px;
  background-color: var(--adbasebgcolor);
  border-radius: 12px;
  min-width: 100%;
  overflow-x: auto;
  margin-bottom: 100px;
}

.aduserstable {
  width: 100%;
}

.adusersthd {
  min-width: 200px;
}

.aduserstb-head,
.aduserstb-data {
  padding: 20px;
}

.aduserstb-data {
  border-top: 1px solid var(--adbaseactcolor);
}

.adinpfic {
  max-width: 50px;
}

.adinputcon {
  cursor: pointer;
}

.adinputcon input {
  display: none;
}

.adinputcon svg {
  overflow: visible;
}

.path {
  fill: none;
  stroke: #ddd;
  stroke-width: 6;
  stroke-linecap: round;
  stroke-linejoin: round;
  transition: stroke-dasharray 0.5s ease, stroke-dashoffset 0.5s ease;
  stroke-dasharray: 241 9999999;
  stroke-dashoffset: 0;
}

.adinputcon input:checked ~ svg .path {
  stroke-dasharray: 70.5096664428711 9999999;
  stroke-dashoffset: -262.2723388671875;
}

.adusersactions {
  cursor: pointer;
  padding: 8px 14px;
  font-weight: 600;
  border-radius: 10px;
  background-color: transparent;
  color: white;
  border: 1px solid var(--adbasehvcolor);
  transition: 0.4s;
}

.adusersactions:hover {
  background-color: var(--adbaseactcolor);
  transition: 0.4s;
}

.adusactx {
  min-width: 190px;
}

.adusacIcon {
  color: #ddd;
  margin-left: 10px;
}

.adoverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 0;
  animation: fadeIn 0.3s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.aduscfn {
  background-color: var(--addarkbgcolor);
  color: white;
  padding: 20px;
  width: fit-content;
  max-width: 90%;
  max-height: 70%;
  overflow-y: auto;
  border-radius: 10px;
  text-align: center;
  opacity: 0;
  transform: scale(0.8);
  animation: slideIn 0.3s ease-in-out forwards;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.adcanbut {
  padding: 10px;
  background-color: var(--adbasebgcolor);
  border: none;
  border-radius: 15px;
  font-weight: 600;
  min-width: 80%;
  color: white;
  cursor: pointer;
  transition: 0.4s;
}

.adcanbut:hover {
  background-color: var(--adbasehvcolor);
  transition: 0.4s;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.adusacts {
  display: flex;
  flex-direction: column;
}

.adusagdm {
  color: white;
  text-align: start;
  text-decoration: none;
  font-weight: 600;
  border: none;
  border-radius: 10px;
  padding: 7px 14px;
  background-color: transparent;
}

.adusagdm:hover {
  background-color: var(--adbasehvcolor);
}

.adusagdm:hover .aduscaicon {
  color: var(--addarkbgcolor);
}

.aduscaicon {
  font-size: 25px;
  margin-right: 10px;
}

#mgbalance {
  margin-right: 10px;
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid var(--adbasehvcolor);
  outline: none;
  color: white;
  background-color: transparent;
}

#mgbalance:focus {
  border-color: var(--adbasehvcolor);
  outline: 0;
  box-shadow: 0 0 0 0.25rem var(--adbasebgcolor);
}

#mgbalance:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px var(--adbasebgcolor) inset !important;
}

#mgbalance:-webkit-autofill {
  box-shadow: 0 0 0 1000px var(--adbasebgcolor) inset !important;
}

#mgbalance::-webkit-inner-spin-button,
#mgbalance::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.admgb {
  padding-bottom: 20px;
}

.adusbale {
  white-space: nowrap;
  font-weight: 600;
}

.mgbaladd {
  margin-bottom: 8px;
  min-width: 100%;
  padding: 13px;
  background-color: var(--adbasebgcolor);
  border: none;
  border-radius: 12px;
  color: white;
  transition: 0.6s;
}

.mgbaladd:hover {
  transition: 0.6s;
  background-color: var(--adbaseactcolor);
}

.mgbalremove {
  padding: 13px;
  min-width: 100%;
  border: 1px solid var(--adbaseactcolor);
  border-radius: 12px;
  color: white;
  transition: 0.6s;
  background-color: inherit;
}

.mgbalremove:hover {
  background-color: var(--adbasehvcolor);
  transition: 0.6s;
  color: white;
}

.adstatusgr {
  font-size: 24px;
  color: rgb(10, 196, 10);
}

.adstatusre {
  font-size: 24px;
  color: red;
}

.aduserstb-data.online {
  color: rgb(10, 196, 10);
  font-weight: bold;
  min-width: 150px;
}

.aduserstb-data.offline {
  color: red;
  font-weight: bold;
  min-width: 150px;
}

.aduserstb-data.banned {
  color: red;
  font-weight: bold;
  min-width: 150px;
}

.adhgfq {
  text-align: start;
  margin-left: 10px;
}

.adtranstd {
  border-bottom: 1px solid var(--adbaseactcolor);
  padding-top: 10px;
}

.adtransdate {
  color: #c4c4c4;
  font-weight: 800;
}

.adtranstb {
  width: 100%;
  overflow: auto;
  min-height: 100%;
}

.adcredtext {
  max-width: 200px;
  word-wrap: break-word;
  white-space: normal;
}

.adbalicon {
  font-size: 25px;
}

.adedtform {
  display: grid;
  gap: 20px;
  text-align: start;
}

#adEemail,
#uname,
#adpass {
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid var(--adbasehvcolor);
  color: white;
  background-color: var(--addarkbgcolor);
}

#adEemail:focus,
#uname:focus,
#adpass:focus {
  border-color: var(--adbasehvcolor);
  outline: 0;
  box-shadow: 0 0 0 0.25rem var(--adbasebgcolor);
}

#adEemail:-webkit-autofill:focus,
#uname:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px var(--adbasebgcolor) inset !important;
}

.adeddsave {
  margin-top: 5px;
  padding: 20px;
  background-color: none;
  border-radius: 13px;
  border: 1px solid var(--adbasehvcolor);
  transition: 0.5s;
  background-color: inherit;
  color: white;
}

.ademdd {
  color: var(--adtextbgcolor);
}

.adeddsave:hover {
  background-color: var(--adbasehvcolor);
  transition: 0.5s;
  color: white;
}

.adseere {
  fill: var(--adbasehvcolor);
}

.adbalformac {
  display: flex;
  align-items: center;
}

.aduserfunc {
  background-color: var(--adbasebgcolor);
  margin-bottom: 20px;
  margin-left: auto;
  color: white;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 10px;
  width: fit-content;
}

.adadduser {
  background-color: transparent;
  border: none;
  transition: 0.5s;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.adadduserIcon {
  font-size: 28px;
  transition: 0.5s;
}

.adadduserfloating-text {
  color: #fff;
  padding: 5px;
  font-weight: 700;
  transition: 0.5s;
}

.adadduser:hover .adadduserIcon {
  color: var(--adbasehvcolor);
  transition: 0.5s;
}

.adadduser:hover .adadduserfloating-text {
  color: var(--adtextbgcolor);
  transition: 0.5s;
}

.adusoverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 0;
  animation: fadeIn 0.3s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.aduscon {
  background-color: var(--addarkbgcolor);
  color: white;
  padding: 20px;
  width: fit-content;
  max-width: 90%;
  max-height: 70%;
  overflow-y: auto;
  border-radius: 10px;
  text-align: center;
  opacity: 0;
  transform: scale(0.8);
  animation: slideIn 0.3s ease-in-out forwards;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.aduscantbut {
  padding: 10px;
  background-color: var(--adbasebgcolor);
  border: none;
  border-radius: 15px;
  font-weight: 600;
  min-width: 80%;
  color: white;
  cursor: pointer;
  transition: 0.4s;
}

.aduscantbut:hover {
  background-color: var(--adbasehvcolor);
  transition: 0.4s;
}

.adusform {
  display: flex;
  flex-direction: column;
}

#adususername,
#adusemail,
#aduspassword {
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid var(--adbasehvcolor);
  color: white;
  margin-bottom: 12px;
  background-color: var(--addarkbgcolor);
}

#adususername:focus,
#adusemail:focus,
#aduspassword:focus,
#searchfield:focus {
  border-color: var(--adbasehvcolor);
  outline: 0;
  box-shadow: 0 0 0 0.25rem var(--adbasebgcolor);
}

#adususername:-webkit-autofill,
#adusemail:-webkit-autofill,
#aduspassword:-webkit-autofill,
#searchfield:-webkit-autofill {
  box-shadow: 0 0 0 1000px var(--adbasebgcolor) inset !important;
}

#adususername:-webkit-autofill:focus,
#adusemail:-webkit-autofill:focus,
#aduspassword:-webkit-autofill:focus,
#searchfield:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px var(--adbasebgcolor) inset !important;
}

.aduslabel {
  font-weight: 600;
  text-align: start;
}

#searchfield {
  width: 100%;
  border-radius: 20px;
  padding: 10px 20px;
  border: 1px solid var(--adbasehvcolor);
  color: white;
  background-color: var(--addarkbgcolor);
  height: auto;
}

.adusfucser {
  position: relative;
}

.aduscancelsearchIcon {
  color: var(--adtextbgcolor);
  font-size: 25px;
  top: 15%;
  cursor: pointer;
  position: absolute;
  right: 5%;
}

@media (max-width: 800px) {
  .adusers {
    height: 100%;
    font-size: var(--bodyfontsize);
  }
  .aduserscon {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 100px;
  }
  .mgbalanceactions {
    width: 100%;
  }
  #mgbalance {
    margin-right: 0px;
  }
  .mgbaladd {
    margin-top: 9px;
  }
  .adbalformac {
    flex-direction: column;
  }
}
