[data-theme="dark"] {
  --bgcolor: var(--bgdarkcolor);
  --bgmaincolor: #161616;
  --textcolor: white;
  --cardbgcolor: #1f1f1f;
  --bordercolor: #333;
}

[data-theme="light"] {
  --bgcolor: var(--bglightcolor);
  --bgmaincolor: #f3f1f1;
  --textcolor: black;
  --cardbgcolor: white;
  --bordercolor: #ddd;
}

.cldashboard {
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: var(--bgcolor);
  color: var(--textcolor);
}

.cldashboardmaincon {
  margin: 100px 20px 50px 320px;
}

.cldash-stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 40px;
}

.cldash-stats-card {
  background-color: var(--cardbgcolor);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  border: 1px solid var(--bordercolor);
}

.cldash-stats-icon {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: var(--stbaseactcolor);
}

.cldash-stats-card h3 {
  margin-bottom: 10px;
  font-weight: bold;
}

.cldash-stats-card p {
  font-size: 2rem;
  margin: 0;
}

.cldash-charts-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 40px;
}

.cldash-chart-card {
  background-color: var(--cardbgcolor);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--bordercolor);
}

.cldash-chart-placeholder {
  height: 200px;
  background-color: var(--bordercolor);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--textcolor);
}

.cldash-recent-activity {
  background-color: var(--cardbgcolor);
  padding: 20px;
  border-radius: 8px;
  overflow-x: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--bordercolor);
}

.cldash-activity-placeholder {
  height: 150px;
  background-color: var(--bordercolor);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--textcolor);
}

.cldash-recent-activity-table {
  width: 100%;
}

.cldash-recent-td {
  min-width: 150px;
  padding: 15px;
}

.cldash-recent-td.inprogress,
.cldash-recent-td.Solved,
.cldash-recent-td.Completed {
  font-weight: bold;
  color: green;
}

.cldash-recent-td.Failed,
.cldash-recent-td.Partial,
.cldash-recent-td.Canceled {
  font-weight: bold;
  color: red;
}

.cldash-recent-td.Pending {
  font-weight: bold;
  color: yellow;
}

.cldash-recent-activity-table tr:not(:last-child) {
  border-bottom: 1px solid var(--stbasebgcolor);
}

.cldash-link-td {
  padding: 20px;
  text-decoration: none;
  font-weight: 600;
  color: var(--stbaseactcolor);
}

.cldash-text {
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media (max-width: 800px) {
  .cldashboard {
    height: unset;
    overflow: unset;
  }
  .cldash-stats-grid {
    grid-template-columns: 1fr;
  }

  .cldash-charts-container {
    grid-template-columns: 1fr;
  }

  .cldashboardmaincon {
    margin: 100px 20px;
    font-size: var(--bodyfontsize);
  }
}
