.fetchingDataCon {
  height: 500px;
  width: auto;
  padding: 20px;
  background-color: var(--adbasebgcolor);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fdcontent {
  transform: scale(2);
}

.adeditservspinner {
  position: relative;
  width: 20px;
  height: 20px;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.adeditservspinner::before,
.adeditservspinner::after {
  border: 5px solid var(--adtextbgcolor);
  border-radius: 50%;
  position: absolute;
  content: "";
  display: block;
}

.adeditservspinner::before {
  width: 11.6px;
  height: 11.6px;
  border-bottom-color: transparent;
  border-left-color: transparent;
  animation: adeditservspinner-1o3y8q 0.75s infinite linear reverse;
}

.adeditservspinner::after {
  animation: adeditservspinner-1o3y8q 0.5s infinite linear;
  height: 20px;
  width: 20px;
  border-right-color: transparent;
  border-top-color: transparent;
}

@keyframes adeditservspinner-1o3y8q {
  to {
    transform: rotate(360deg);
  }
}
