/* pageNotFound.css */
.pagenotfound-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 0 2rem;
  background-color: #1c1c24;
  color: #f8f8f8;
  position: relative;
  overflow: hidden;
}

.pagenotfound-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 2;
  text-align: center;
  max-width: 900px;
  margin: auto;
}

.pagenotfound-title {
  font-size: 8rem;
  font-weight: 700;
  margin: 0;
  color: #ff5a5f;
  text-shadow: 0px 0px 10px rgba(255, 90, 95, 0.7);
  animation: flicker 1.5s infinite alternate;
}

.pagenotfound-subtitle {
  font-size: 2.5rem;
  margin: 1rem 0;
  color: #ffe66d;
}

.pagenotfound-message {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: #d1d5db;
}

.pagenotfound-button {
  padding: 0.8rem 2rem;
  font-size: 1.1rem;
  font-weight: bold;
  color: #1c1c24;
  background-color: #ffe66d;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.pagenotfound-button:hover {
  transform: scale(1.05);
  background-color: #ff5a5f;
  color: #fff;
}

.pagenotfound-goback-button {
  padding: 0.8rem 2rem;
  font-size: 1.1rem;
  font-weight: bold;
  color: #1c1c24;
  background-color: #5f4a68;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  margin-top: 1rem;
}

.pagenotfound-goback-button:hover {
  transform: scale(1.05);
  background-color: #742c82;
  color: #fff;
}

.pagenotfound-graphic {
  margin-top: 3rem;
}

.pagenotfound-image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

.pagenotfound-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle,
    rgba(28, 28, 36, 0.6) 0%,
    rgba(28, 28, 36, 0.9) 70%
  );
  z-index: 1;
}

@keyframes flicker {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
}

.pagenotfound-icon {
  margin-bottom: 2rem;
  animation: error-bounce 2s infinite;
}

@keyframes error-bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@media (min-width: 768px) {
  .pagenotfound-content {
    flex-direction: row;
    text-align: left;
  }

  .pagenotfound-text {
    margin-right: 2rem;
  }

  .pagenotfound-graphic {
    margin-top: 0;
  }
}
