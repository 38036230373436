.cpay {
  background-color: var(--bglightcolor);
  height: 100vh;
  overflow-y: auto;
}

.dark-cpay {
  height: 100vh;
  overflow-y: auto;
  background-color: var(--bgdarkcolor);
  color: white;
}

.pay-con {
  margin-left: 360px;
  margin-right: 20px;
  margin-top: 100px;
}

.cpayCon {
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  width: 60%;
  margin: auto;
}

.dark-cpayCon {
  background-color: black;
  border-radius: 20px;
  padding: 20px;
  color: inherit;
  width: 60%;
  margin: auto;
}

.cpgdescription {
  margin-top: 20px;
  font-size: inherit;
  font-family: inherit;
  white-space: pre-wrap;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid var(--stbasebgcolor);
}

.cppaymentquantity {
  display: flex;
  justify-content: space-between;
}

.cppaymentquantity span span {
  font-weight: 600;
  color: var(--sttextbgcolor);
}

#paymentQuantity {
  border: 1px solid var(--stbasebgcolor);
  background-color: transparent;
  margin-top: 20px;
  width: 100%;
  border-radius: 10px;
  padding: 10px 20px;
  color: inherit;
}

#paymentQuantity:focus {
  border-color: var(--stbaseactcolor);
  outline: 0;
  box-shadow: 0 0 0 0.25rem var(--stbasehvcolor);
}

#paymentQuantity:-webkit-autofilll {
  box-shadow: 0 0 0 1000px var(--stbasebgcolor) inset !important;
}

#paymentQuantity:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px var(--stbasebgcolor) inset !important;
}

.cpgpaybtn {
  border: none;
  background-color: var(--stbasebgcolor);
  color: white;
  margin-top: 20px;
  width: 100%;
  font-weight: 600;
  padding: 10px 15px;
  transition: 0.5s;
  border-radius: 10px;
}

.cpgpaybtn:hover {
  transition: 0.5s;
  background-color: var(--stbaseactcolor);
}

.cpgpaybtn:hover .cpgpaybtnicon {
  transition: 0.5s;
  transform: translateX(20px);
}

.cpgpaybtnicon {
  transition: 0.5s;
  margin-left: 10px;
  font-size: 22px;
}

#paymentQuantity::-webkit-inner-spin-button,
#paymentQuantity::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

@media (max-width: 1000px) {
  .cpayCon,
  .dark-cpayCon {
    width: 100%;
  }
}

@media (max-width: 800px) {
  .cpay,
  .dark-cpay {
    height: 100dvh;
    font-size: var(--bodyfontsize);
  }
  .pay-con {
    margin-left: 20px;
    margin-bottom: 60px;
  }
}
