[data-theme="dark"] {
  --bgcolor: var(--bgdarkcolor);
  --bgmaincolor: #161616;
  --textcolor: white;
}

[data-theme="light"] {
  --bgcolor: var(--bglightcolor);
  --bgmaincolor: #f3f1f1;
  --textcolor: black;
}

.clrefills {
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  background-color: var(--bgcolor);
  color: var(--textcolor);
}

.clrefillsmaincon {
  margin: 100px 20px 20px 320px;
}

.clrefillstable {
  background-color: var(--bgmaincolor);
  margin-top: 20px;
  border-radius: 10px;
  overflow-x: auto;
  width: 100%;
}

.clrefillstable table {
  width: 100%;
}

.clrefillstable table tr:not(:last-child) {
  border-bottom: 1px solid var(--stbaseactcolor);
}

.clrefillstable table th,
.clrefillstable table td {
  padding: 15px;
}

.clrefiltd.inprogress,
.clrefiltd.Completed {
  font-weight: bold;
  color: green;
}

.clrefiltd.Failed,
.clrefiltd.Partial,
.clrefiltd.Rejected,
.clrefiltd.Canceled {
  font-weight: bold;
  color: red;
}

.clrefiltd.Pending {
  font-weight: bold;
  color: yellow;
}

.clreforder {
  font-weight: 600;
  text-decoration: none;
  transition: 0.3s ease;
  color: var(--sttextbgcolor);
}

.clreforder:hover {
  text-decoration: underline;
}

.clreflinktd {
  max-width: 170px;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media (max-width: 800px) {
  .clrefillsmaincon {
    margin: 100px 20px;
    font-size: var(--bodyfontsize);
  }
}
