.pagination {
  padding: 30px 0px;
  display: flex;
  list-style-type: none;
  cursor: pointer;
  width: 100%;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
}

.pagination a {
  color: unset;
}

.prv-item {
  color: black;
  border-radius: 14px;
  padding: 14px;
  background-color: white;
  position: absolute;
  left: 0px;
}

.dark-prv-item {
  color: white;
  border-radius: 14px;
  padding: 14px;
  background-color: black;
  position: absolute;
  left: 0px;
}

.next-item {
  color: black;
  border-radius: 14px;
  padding: 14px;
  background-color: white;
  position: absolute;
  right: 0px;
}

.dark-next-item {
  color: white;
  border-radius: 14px;
  padding: 14px;
  background-color: black;
  position: absolute;
  right: 0px;
}

.disabled-pag {
  background-color: var(--sitecolor);
  color: white;
  border-radius: 14px;
  padding: 14px;
}

.page-item .active {
  background-color: var(--sitecolor);
}

.next-link,
.prv-link {
  text-decoration: none !important;
}

.page-item {
  background-color: white;
  margin-right: 5px;
  border-radius: 10px;
  color: black;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 17px;
  border: 1px solid rgb(228, 228, 228);
  line-height: 1.17;
}

.dark-page-item {
  background-color: black;
  margin-right: 5px;
  border-radius: 10px;
  color: white;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 17px;
  border: 1px solid rgb(27, 27, 27);
  line-height: 1.17;
}

.dark-page-item:hover {
  background-color: var(--sitecolor);
  color: white;
}

.page-item:hover {
  background-color: var(--sitecolor);
  color: white;
}

.page-link {
  padding: 10px;
  border: 1px solid #e6eaed;
  text-decoration: none;
  color: #333;
  cursor: pointer;
  background-color: white;
  border-radius: 14px;
}

.acv {
  color: #fff;
  background-color: var(--sitecolor);
}

@media (max-width: 800px) {
  .pagination {
    margin-bottom: 100px;
  }
}
