.adservices {
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: var(--addarkbgcolor);
  color: white;
}

.adservicesmaincon {
  position: relative;
  margin-left: 360px;
  margin-right: 20px;
  margin-bottom: 30px;
  top: 20px;
}

.adservicesstatcon {
  background-color: var(--adbasebgcolor);
  padding: 20px;
  border-radius: 20px;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: 0.5s;
  margin-bottom: 20px;
}

.adservicesstatcon:hover {
  background-color: var(--adbaseactcolor);
  transition: 0.5s;
}

.adtotalservicesiconcon {
  display: flex;
  justify-content: center;
  font-size: inherit;
  align-items: center;
  width: 6rem;
  height: 4rem;
  margin-right: 40px;
  opacity: 1;
  background: linear-gradient(
    195deg,
    var(--addarkbgcolor),
    var(--adbaseactcolor)
  );
  color: rgb(255, 255, 255);
  border-radius: 0.75rem;
  box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem,
    var(--addarkbgcolor) 0rem 0.4375rem 0.625rem -0.3125rem;
}

.adservicesmainstatcon {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.adservicesmainstatcon .adordersstatcon {
  margin-right: 20px;
}

.adservicesupbtn {
  background-color: transparent;
  border: none;
  transition: 0.5s;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.adservicesupbtn:not(:last-child) {
  margin-right: 20px;
}

.adservicesupbtn .orsearIcon,
.adservicesupbtn span {
  transition: 0.5s;
}

.adservicesupbtn:hover .orsearIcon {
  color: var(--adbasehvcolor);
  transition: 0.5s;
}

.adservicesupbtn:hover span {
  color: var(--adtextbgcolor);
  transition: 0.5s;
}

.adservicesimportcon {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 0;
  animation: fadeIn 0.3s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.adservicesimport {
  background-color: var(--addarkbgcolor);
  color: white;
  padding: 20px;
  width: 800px;
  max-width: 90%;
  max-height: 90%;
  min-height: 50%;
  overflow: hidden;
  border-radius: 10px;
  opacity: 0;
  transform: scale(0.8);
  animation: slideIn 0.3s ease-in-out forwards;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.adservimportcancel {
  position: absolute;
  right: 5px;
  top: 5px;
  -webkit-box-shadow: 1px 2px 7px 4px var(--addarkbgcolor);
  -moz-box-shadow: 1px 2px 7px 4px var(--addarkbgcolor);
  box-shadow: 1px 2px 7px 4px var(--addarkbgcolor);
  background-color: var(--adbasebgcolor);
  padding: 10px;
  cursor: pointer;
  width: fit-content;
  transition: 0.4s;
  border-radius: 50%;
}

.adservimportcancel:hover {
  transform: scale(1.2);
  transition: 0.4s;
}

.adservimportback {
  position: absolute;
  left: 5px;
  top: 5px;
  -webkit-box-shadow: 1px 2px 7px 4px var(--addarkbgcolor);
  -moz-box-shadow: 1px 2px 7px 4px var(--addarkbgcolor);
  box-shadow: 1px 2px 7px 4px var(--addarkbgcolor);
  background-color: var(--adbasebgcolor);
  padding: 10px;
  cursor: pointer;
  width: fit-content;
  transition: 0.4s;
  border-radius: 50%;
}

.adservimportback:hover {
  transform: scale(1.2);
  transition: 0.4s;
}

.adservimicon {
  font-size: 25px;
  color: white;
}

.adservicesimporthead {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.adservicesimporthead h3 {
  font-weight: 600;
  margin-top: 10px;
}

.adservicesimporthdicon {
  width: 64px;
  height: 64px;
}

.adservicesimportbody {
  margin-top: 20px;
  height: 100%; /* Occupy full height of the main container */
}

.providerOptions {
  width: 100%;
  font-weight: 600;
}

.adImportCategoryOptionCon {
  margin: 20px 0px;
}

.adservicesimpse {
  margin-top: 20px;
  background-color: var(--adbasebgcolor);
  border-radius: 12px;
  width: 100%;
  overflow-y: auto; /* Allow vertical scrolling */
  max-height: 900px; /* Set a maximum height for the container */
}

.adservicesimpse table {
  width: 100%;
}

.adservicesimptd {
  padding: 20px;
  border-top: 1px solid var(--adbaseactcolor);
}

.adservicesimptbth {
  padding: 20px;
}

.adservicesimfetching {
  margin-top: 20px;
}

.adserviceseimNS {
  position: fixed;
  bottom: 10px;
  color: white;
  background-color: var(--adbaseactcolor);
  border: none;
  padding: 20px;
  font-weight: 600;
  width: 90%;
  transition: 0.4s;
  border-radius: 10px;
}

.adserviceseimNS:hover {
  background-color: var(--adbasehvcolor);
  transition: 0.4s;
}

.adservicesimpmaxwi {
  max-width: 100px;
}

.adservicesimpservc {
  width: 100%;
}

.adservicesimpfct {
  font-weight: 600;
  margin-bottom: 20px;
}

#adImportPercent {
  width: 40%;
}

#adImportExtraCharge {
  width: 100%;
}

#adImportPercent,
#adImportExtraCharge {
  background-color: transparent;
  border: 1px solid var(--adbaseactcolor);
  border-radius: 10px;
  padding: 10px 15px;
  color: white;
}

#adImportPercent:focus,
#adImportExtraCharge:focus {
  border-color: var(--adbaseactcolor);
  outline: 0;
  box-shadow: 0 0 0 0.25rem var(--adbasehvcolor);
}

#adImportPercent:-webkit-autofill,
#adImportExtraCharge:-webkit-autofill {
  box-shadow: 0 0 0 1000px var(--adbasebgcolor) inset !important;
}

#adImportPercent:-webkit-autofill:focus,
#adImportExtraCharge:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px var(--adbasebgcolor) inset !important;
}

.adservicesimpfstable {
  margin-top: 20px;
  margin-bottom: 80px;
  background-color: var(--adbasebgcolor);
  border-radius: 12px;
  width: 100%;
  overflow-y: auto; /* Allow vertical scrolling */
  max-height: 900px; /* Set a maximum height for the container */
}

.adservicesimpfstable table {
  width: 100%;
}

.adervfinalimhd {
  padding: 20px;
  color: var(--adtextbgcolor);
}

.adservfinalimtd {
  padding: 20px;
  border-top: 1px solid var(--adbaseactcolor);
}

.adsearchimpservc {
  margin-top: 20px;
}

.adsearchbtnimportserv {
  background-color: var(--adbasebgcolor);
  margin-bottom: 20px;
  border-radius: 20px;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  float: right;
  padding: 10px 15px;
  transition: 0.5s;
  color: white;
}

.adsearchbtnimportserv .orsearIcon,
.adsearchbtnimportserv span {
  transition: 0.5s;
}

.adsearchbtnimportserv:hover .orsearIcon {
  color: var(--adbasehvcolor);
  transition: 0.5s;
}

.adsearchbtnimportserv:hover span {
  color: var(--adtextbgcolor);
  transition: 0.5s;
}

@media (max-width: 800px) {
  .adservicesmainstatcon .adordersstatcon {
    margin-right: 0px;
  }
  .adservices {
    height: 100dvh;
    font-size: var(--bodyfontsize);
  }
  .adservicesmaincon {
    margin-left: 20px;
    margin-bottom: 100px;
  }
}
