[data-theme="dark"] {
  --bgcolor: var(--bgdarkcolor);
  --bgmaincolor: #121212;
  --textcolor: white;
}

[data-theme="light"] {
  --bgcolor: var(--bglightcolor);
  --bgmaincolor: #f3f1f1;
  --textcolor: #333;
}

.support {
  background-color: var(--bglightcolor);
  min-height: 100vh;
  overflow-y: auto;
}

.dark-support {
  background-color: var(--bgdarkcolor);
  min-height: 100vh;
  overflow-y: auto;
}

.support-container {
  margin-left: 320px;
  margin-bottom: 100px;
  margin-right: 20px;
  margin-top: 100px;
}

.dark-support-form {
  background-color: black;
  color: white;
  width: 80%;
  border-radius: 20px;
  padding: 20px;
  margin: auto;
}

.support-form {
  background-color: white;
  width: 80%;
  border-radius: 20px;
  padding: 20px;
  margin: auto;
}

.ord-form,
.api-form,
.payment-form {
  display: flex;
  flex-direction: column;
}

#orderId,
#transactionId,
#amont,
#emailId,
#discountServiceId,
#whatsapp,
#websiteUrl {
  outline: none;
  border-radius: 20px;
  padding: 5px;
  padding-left: 12px;
  background-color: white;
  border-color: grey;
}

#dark-orderId,
#dark-transactionId,
#dark-amont,
#dark-emailId,
#dark-discountServiceId,
#dark-whatsapp,
#dark-websiteUrl {
  outline: none;
  border-radius: 20px;
  padding: 5px;
  padding-left: 12px;
  background-color: black;
  color: white;
  border-color: grey;
}

#orderId:focus,
#transactionId:focus,
#amont:focus,
#emailId:focus,
#discountServiceId:focus,
#whatsapp:focus,
#websiteUrl:focus {
  border-color: var(--bgdarkcolor);
  outline: 0;
  box-shadow: 0 0 0 0.15rem var(--sitecolor);
}

#orderId:-webkit-autofill:focus,
#transactionId:-webkit-autofill:focus,
#amont:-webkit-autofill:focus,
#emailId:-webkit-autofill:focus,
#discountServiceId:-webkit-autofill:focus,
#whatsapp:-webkit-autofill:focus,
#websiteUrl:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px white inset !important;
}

#orderId:-webkit-autofill,
#transactionId:-webkit-autofill,
#amont:-webkit-autofill,
#emailId:-webkit-autofill,
#discountServiceId:-webkit-autofill,
#whatsapp:-webkit-autofill,
#websiteUrl:-webkit-autofill {
  box-shadow: 0 0 0 1000px white inset !important;
}

#dark-orderId:focus,
#dark-transactionId:focus,
#dark-amont:focus,
#dark-emailId:focus,
#dark-discountServiceId:focus,
#dark-whatsapp:focus,
#dark-websiteUrl:focus {
  border-color: var(--bgdarkcolor);
  outline: 0;
  box-shadow: 0 0 0 0.2rem var(--sitecolor);
}

#dark-orderId:-webkit-autofill:focus,
#dark-transactionId:-webkit-autofill:focus,
#dark-amont:-webkit-autofill:focus,
#dark-emailId:-webkit-autofill:focus,
#dark-discountServiceId:-webkit-autofill:focus,
#dark-whatsapp:-webkit-autofill:focus,
#dark-websiteUrl:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px var(--bgdarkcolor) inset !important;
}

#dark-orderId:-webkit-autofill,
#dark-transactionId:-webkit-autofill,
#dark-amont:-webkit-autofill,
#dark-emailId:-webkit-autofill,
#dark-discountServiceId:-webkit-autofill,
#dark-whatsapp:-webkit-autofill,
#dark-websiteUrl:-webkit-autofill {
  box-shadow: 0 0 0 1000px var(--bgdarkcolor) inset !important;
}

#mesg {
  height: 180px;
  border-radius: 40px;
  margin: auto;
  width: 100%;
  padding: 20px;
  outline-color: var(--sitecolor);
}

#dark-mesg:focus {
  border-color: var(--bgdarkcolor);
  outline: 0;
  box-shadow: 0 0 0 0.2rem var(--sitecolor);
}

#dark-mesg {
  height: 180px;
  border-radius: 40px;
  margin: auto;
  width: 100%;
  color: white;
  background-color: black;
  padding: 20px;
  outline-color: var(--sitecolor);
}

#amont::-webkit-inner-spin-button,
#amont::-webkit-outer-spin-button,
#discountServiceId::-webkit-inner-spin-button,
#discountServiceId::-webkit-outer-spin-button,
#transactionId::-webkit-inner-spin-button,
#transactionId::-webkit-outer-spin-button,
#whatsapp::-webkit-inner-spin-button,
#whatsapp::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

#dark-amont::-webkit-inner-spin-button,
#dark-amont::-webkit-outer-spin-button,
#dark-discountServiceId::-webkit-inner-spin-button,
#dark-discountServiceId::-webkit-outer-spin-button,
#dark-transactionId::-webkit-inner-spin-button,
#dark-transactionId::-webkit-outer-spin-button,
#dark-whatsapp::-webkit-inner-spin-button,
#dark-whatsapp::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

#amont,
#discountServiceId,
#transactionId,
#whatsapp {
  -moz-appearance: textfield;
  appearance: textfield;
}

.subBtn {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.subMessage {
  padding: 10px 20px;
  background-color: var(--stbaseactcolor);
  border-radius: 10px;
  font-weight: 600;
  border: none;
  color: white;
  transition: 0.5s;
}

.subMessage:hover {
  background-color: var(--stbasehvcolor);
  transition: 0.5s;
}

.support-tickets {
  width: 80%;
  margin: auto;
  overflow-x: auto;
  border-radius: 10px;
}

.st-id {
  border-top-left-radius: 10px;
}

.st-lu {
  border-top-right-radius: 10px;
  min-width: 100px;
}

.st-luv {
  min-width: 260px;
}

.st-tb {
  border-radius: 10px;
  background-color: white;
  width: 100%;
  padding: 10px;
}

.dark-st-tb {
  border-radius: 20px;
  background-color: black;
  color: white;
  width: 100%;
  padding: 10px;
}

.st-data .st-icon {
  font-size: calc(1em + 20px);
  margin-right: 10px;
  vertical-align: middle;
}

.st-malink {
  min-width: 260px;
}

.st-link {
  color: black;
}

.dark-st-link {
  color: white;
}

.dark-st-link,
.st-link {
  vertical-align: middle;
  font-size: inherit;
  display: inline-block;
  font-weight: bold;
  color: inherit;
  text-decoration: none;
}

.dark-st-link:hover,
.st-link:hover {
  text-decoration: underline;
}

.st-status {
  background-color: var(--sitecolor);
}

.st-pd-status,
.st-status,
.st-sv-status,
.st-as-status,
.st-wfr-status,
.st-reop-status {
  font-weight: bold;
  padding: 14px 22px;
  min-width: 170px;
  width: 60%;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  color: white;
  border-radius: 100px;
}

.st-pd-status {
  background-color: rgb(255, 255, 0);
}

.st-wfr-status {
  background-color: orange;
}

.st-as-status {
  background-color: rgb(250, 59, 11);
}

.st-reop-status {
  background-color: #1e90ff;
}

.st-sv-status {
  background-color: #249b23;
}

.notfoundforsupportstyle {
  width: 80%;
  margin: auto;
}

.st-head,
.st-data {
  padding: 20px;
}

.clmaxwifseromob {
  margin-top: 20px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.clmbsupportstatus.Solved {
  color: green;
}

.clmbsupportstatus.Waiting.For.Response {
  color: orange;
}

.clmbsupportstatus.Pending {
  color: yellow;
}

.clmbsupportstatus.Reopened {
  color: #1e90ff;
}

.clmbsupportstatus.Answered {
  background-color: rgb(250, 59, 11);
}

.clmbservicena .st-icon {
  font-size: 1.2rem;
}

@media (max-width: 800px) {
  .support,
  .dark-support {
    font-size: var(--bodyfontsize);
  }
  .support-container {
    margin-left: 20px;
    margin-bottom: 100px;
  }
  .clmaxwifseromob {
    width: 100%;
  }
  .dark-support-form {
    min-width: 100%;
  }
  .support-form {
    min-width: 100%;
  }
  .notfoundforsupportstyle {
    min-width: 100%;
  }
  .support-tickets {
    min-width: 100%;
    background-color: transparent;
    border-radius: 20px;
    overflow-x: auto;
  }
  .dark-st-tb {
    overflow-x: auto;
    min-width: 100%;
  }
  .st-malink {
    min-width: 650px;
  }
}
