@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

body {
  margin: 0;
  font-family: "Rubik", sans-serif !important;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-column-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.p-20 {
  padding: 20px;
}

.m-20 {
  margin: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.gap-20 {
  gap: 20px;
}
