.adcategories {
  width: 100%;
  height: 100dvh;
  overflow: auto;
  background-color: var(--addarkbgcolor);
  color: white;
}

.adcategoriesmaincon {
  position: relative;
  margin-left: 360px;
  margin-right: 20px;
  margin-bottom: 30px;
  top: 20px;
}

.adcategorybtnadd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  border: none;
  background-color: transparent;
  transition: 0.4s;
}

.adcategorybtnadd:hover {
  color: var(--adtextbgcolor);
  transition: 0.4s;
}

@media (max-width: 800px) {
  .adcategories {
    font-size: var(--bodyfontsize);
  }
  .adcategoriesmaincon {
    margin-left: 20px;
    margin-bottom: 100px;
  }
}
