.advcviewchat {
  width: 100%;
  height: 100%;
  background-color: var(--addarkbgcolor);
  color: white;
}

.adviewcmaincon {
  position: relative;
  margin-left: 360px;
  margin-right: 20px;
  margin-bottom: 30px;
  top: 20px;
}

.advcviewchcol {
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

.advcviewchat-container {
  flex: 0 1 800px;
  padding: 20px;
  background-color: var(--adbasebgcolor);
  border-radius: 20px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

.advcchat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 2px solid var(--adbaseactcolor);
  padding-bottom: 10px;
}

.advcuser-status {
  font-weight: bold;
  padding: 10px;
  border-radius: 5px;
}

.advcuser-status.online {
  color: white;
  background-color: green;
  border-radius: 9px;
  padding: 9px;
}

.advcuser-status.offline {
  color: white;
  background-color: red;
  border-radius: 9px;
  padding: 9px;
}

.advcchat-messages {
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: 20px;
  padding: 10px;
  scroll-behavior: smooth;
  background-color: var(--adbasebgcolor);
  border-radius: 10px;
}

.advcmessage {
  padding: 10px 15px;
  border-radius: 10px;
  margin-bottom: 10px;
  position: relative;
  max-width: 70%;
  word-wrap: break-word;
  display: flex;
  align-items: center;
}

.advcmessage.sent {
  background-color: var(--adbaseactcolor);
  align-self: flex-end;
  color: white;
  margin-left: auto;
}

.advcmessage.received {
  background-color: var(--addarkbgcolor);
  align-self: flex-start;
  color: white;
  margin-right: auto;
}

.advcmessage-content {
  white-space: pre-wrap;
  padding-right: 25px;
}

.advcmessage-content img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.advcmessage-timestamp {
  font-size: 0.8em;
  color: #ddd;
  position: absolute;
  bottom: -5px;
  right: 10px;
}

.advcchat-input {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.advcsend-button {
  padding: 10px 30px;
  background-color: var(--adbaseactcolor);
  color: white;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  align-self: flex-end;
}

.advcsend-button:hover {
  background-color: var(--adbasehvcolor);
}

.adv-user-details-container {
  width: 100%;
  padding: 15px;
  background-color: var(--adbasebgcolor);
  height: fit-content;
  border-radius: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  color: white;
}

.adv-user-details-container h3 {
  margin-bottom: 15px;
  color: var(--adbasehvcolor);
  font-weight: bold;
}

.adv-user-detail {
  margin-bottom: 10px;
  font-size: 1.1em;
}

.advc-user-action {
  padding: 10px 20px;
  color: var(--adtextbgcolor);
  border: 2px solid var(--adbasehvcolor);
  font-weight: 600;
  background-color: var(--adbaseactcolor);
  border-radius: 10px;
}

.advc-right-container {
  display: flex;
  gap: 20px;
  width: 100%;
  max-width: 500px;
  flex-direction: column;
}

.advc-ticket-container {
  padding: 15px;
  width: 100%;
  background-color: var(--adbasebgcolor);
  height: fit-content;
  border-radius: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  color: white;
  margin-bottom: 20px;
}

.advc-ticket-container h3 {
  margin-bottom: 15px;
  color: var(--adbasehvcolor);
  font-weight: bold;
}

.advc-ticket-action {
  display: block;
  width: 100%;
  padding: 10px 20px;
  margin-bottom: 10px;
  background-color: var(--adbaseactcolor);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease-in-out;
}

.advc-ticket-action:hover {
  background-color: var(--adbasehvcolor);
}

.advc-sender-select {
  margin-top: 10px;
  margin-bottom: 10px;
}

.advc-sender-input {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: none;
  outline: 1px solid var(--adbaseactcolor);
  background-color: var(--adbasebgcolor);
  color: white;
}

.advc-sender-input:focus {
  outline: 1px solid var(--adbaseactcolor);
  box-shadow: 0 0 0 0 var(--adbaseactcolor);
}

.advcmessage .typing-indicator {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--addarkbgcolor);
  border-radius: 10px;
  padding: 10px 15px;
  margin-bottom: 10px;
  max-width: 70%;
  margin-right: auto;
}

.advcmessage .typing-indicator .dot {
  width: 8px;
  height: 8px;
  background-color: #ffffff;
  border-radius: 50%;
  margin-right: 5px;
  animation: typing 1.5s infinite;
}

@keyframes typing {
  0% {
    transform: translateY(0);
    opacity: 0.3;
  }
  50% {
    transform: translateY(-5px);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 0.3;
  }
}

.advcmessage .typing-indicator .dot:nth-child(2) {
  animation-delay: 0.3s;
}

.advcmessage .typing-indicator .dot:nth-child(3) {
  animation-delay: 0.6s;
}

@media (max-width: 800px) {
  .adviewcmaincon {
    margin-left: 20px;
    font-size: var(--bodyfontsize);
    margin-bottom: 100px;
  }
  .adv-user-details-container {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .advcviewchat-container,
  .adv-user-details-container {
    flex: 1 1 100%;
  }
  .advc-right-container {
    max-width: 100%;
  }
}
