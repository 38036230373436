.addesign {
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: var(--addarkbgcolor);
  color: white;
}

.addesignmaincon {
  position: relative;
  margin-left: 360px;
  display: flex;
  flex-wrap: wrap;
  margin-right: 20px;
  top: 20px;
  gap: 20px;
}

.addesignhead {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--adbasebgcolor);
  padding: 10px 0px;
  border-radius: 10px;
}

.addesignbody {
  background-color: var(--adbasebgcolor);
  margin-top: 20px;
  border-radius: 10px;
}

.addesignhd {
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.addesignbody .addesclcon:not(:last-child) {
  border-bottom: 1px solid var(--adbaseactcolor);
}

.addesclcon {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.addesclrs {
  flex-wrap: wrap;
  display: flex;
}

.addesclrs .adcolorpickercon:not(:last-child) {
  margin-right: 20px;
}

.addesnotlr {
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  font-weight: 600;
  color: white;
  background-color: var(--adbaseactcolor);
}

.addesnotlricon {
  font-size: 1.2rem;
  margin-right: 10px;
}

.addesclrs .adcolorpickercon {
  margin: 10px;
}

@media (max-width: 800px) {
  .addesign {
    height: 100%;
    font-size: var(--bodyfontsize);
  }
  .addesign .adcontrolpanel {
    display: none;
  }
  .addesignmaincon {
    margin-left: 20px;
    margin-bottom: 100px;
    top: 0px;
    gap: 0px;
  }
}
