:root {
  --sitecolor: #333;
}

.faqsFull {
  background-color: var(--bglightcolor);
  min-height: 100vh;
  overflow-y: auto;
}

.darkFaqsFull {
  background-color: var(--bgdarkcolor);
  color: white;
  min-height: 100vh;
  overflow-y: auto;
}

.clfaqsmaxwfet {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.faqs-container {
  position: relative;
  top: 100px;
  margin-left: 320px;
  margin-right: 20px;
}

.faqs-details {
  width: 60%;
  padding: 15px;
  margin: auto;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 2px 5px 0px var(--sitecolor);
}

.dark-faqs-details {
  width: 60%;
  background-color: #030303;
  padding: 15px;
  margin: auto;
  color: white;
  border-radius: 10px;
  box-shadow: 0px 2px 5px 0px var(--sitecolor);
}

.apiUL {
  list-style-type: none;
}

.apiLI {
  margin-top: 10px;
}

.dark-faqs-details .apiA {
  color: white;
}

.apiA {
  text-decoration: none;
  font-size: 1.3rem;
  color: #000;
  font-weight: 800;
}

.apiA:hover {
  text-decoration: underline;
  color: var(--sitecolor);
  transition: color 0.5s;
}

.darkVfFull {
  background-color: var(--bgdarkcolor);
  color: white;
  min-height: 100vh;
  overflow-y: auto;
}

.vfFull {
  background-color: var(--bglightcolor);
  min-height: 100vh;
  overflow-y: auto;
}

.vf-container {
  position: relative;
  top: 100px;
  margin-left: 320px;
  margin-right: 20px;
}

.vf-details {
  width: 60%;
  padding: 15px;
  margin: auto;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 2px 5px 0px var(--sitecolor);
}

.dark-vf-details {
  width: 60%;
  padding: 15px;
  margin: auto;
  background-color: rgb(17, 17, 17);
  color: white;
  border-radius: 10px;
  box-shadow: 0px 2px 5px 0px var(--sitecolor);
}

.vf-head {
  display: flex;
}

.vf-body {
  margin-top: 10px;
}

.back {
  color: var(--sitecolor);
  font-size: 24px;
}

@media (max-width: 800px) {
  .darkFaqsFull,
  .faqsFull {
    font-size: var(--bodyfontsize);
  }
  .apiA {
    font-size: inherit;
  }
  .faqs-container {
    margin-left: 20px;
    margin-right: 20px;
  }
  .vf-container {
    margin-left: 20px;
    margin-right: 20px;
  }
  .faqs-details {
    width: 90%;
  }
  .clfaqsmaxwfet {
    width: 100%;
  }
  .dark-faqs-details {
    width: 90%;
  }
  .vf-details {
    width: 90%;
  }
  .dark-vf-details {
    width: 90%;
  }
}
