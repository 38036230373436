.theme_toogle,
.dark_theme_toogle {
  padding: 10px;
  cursor: pointer;
  transition: 0.5s;
  border: 1px solid var(--stbasehvcolor);
  border-radius: 10px;
}

.theme_toogle:hover {
  background: linear-gradient(
    to top left,
    var(--stbaseactcolor),
    var(--bglightcolor),
    var(--bglightcolor),
    var(--stbasehvcolor)
  );
  transition: 0.5s;
}

.theme_toogle {
  background-color: var(--bglightcolor);
}

.dark_theme_toogle {
  background-color: var(--bgdarkcolor);
}

.dark_theme_toogle:hover {
  background: linear-gradient(
    to top left,
    var(--stbaseactcolor),
    var(--bgdarkcolor),
    var(--bgdarkcolor),
    var(--stbasehvcolor)
  );
  transition: 0.5s;
}

.theme_toogle .thicon,
.dark_theme_toogle .thicon {
  color: var(--stbaseactcolor);
  font-size: calc(1rem + 0.5rem);
}
