.adpages {
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: var(--addarkbgcolor);
  color: white;
}

.adpagesmaincon {
  position: relative;
  margin-left: 360px;
  display: flex;
  flex-wrap: wrap;
  margin-right: 20px;
  top: 20px;
  gap: 20px;
}

.adpagesgobackbtn:hover {
  transition: 0.3s;
}

.adpageshead {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--adbasebgcolor);
  padding: 10px 0px;
  border-radius: 10px;
}

.adeditpagehead {
  display: grid;
  align-items: center;
  background-color: var(--adbasebgcolor);
  padding: 10px 0px;
  border-radius: 10px;
}

.adeditpagehead h6 {
  font-weight: 700;
  justify-self: center;
}

.adpagesgobackbtn {
  justify-self: start;
  position: absolute;
  cursor: pointer;
  margin-left: 10px;
  font-size: 1.2rem;
  color: white;
}

.adpageshead h6 {
  font-weight: 700;
}

.adpagesbody {
  margin-top: 20px;
}

.adallpagescon,
.adeditpagecon {
  background-color: var(--adbasebgcolor);
  border-radius: 10px;
}

.adallpagescon .adallpagesul {
  display: flex;
  flex-direction: column;
}

.adallpagescon .adallpagesul .adallpagesli:not(:last-child) {
  border-bottom: 1px solid var(--adbaseactcolor);
}

.adallpagescon .adallpagesul .adallpagesli {
  padding: 20px;
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.adallpagescon .adallpagesli a {
  text-decoration: none;
  color: white;
  transition: 0.5s;
}

.adpageicon {
  font-size: 1.3rem;
  margin-right: 10px;
}

.adpageedicon {
  font-size: 2rem;
  color: var(--adbasehvcolor);
  border-radius: 5px;
  background-color: var(--adbaseactcolor);
  padding: 7px;
  cursor: pointer;
  transition: 0.5s;
}

.adpageedicon:hover {
  background-color: var(--addarkbgcolor);
  transition: 0.5s;
}

.adedithmcon h4 {
  color: var(--adtextbgcolor);
  font-weight: 600;
}

.adedithhome {
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.adedithhome h5 {
  color: var(--adtextbgcolor);
  font-weight: 600;
  font-style: italic;
  padding: 0px 20px;
}

.adedithmcon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.adedtutlinkcon {
  padding: 0px 20px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 800px) {
  .adpages {
    height: 100%;
    font-size: var(--bodyfontsize);
  }
  .adpages .adcontrolpanel {
    display: none;
  }
  .adpagesmaincon {
    margin-left: 20px;
    margin-bottom: 100px;
    top: 0px;
    gap: 0px;
  }
}
