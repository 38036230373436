.adgeneral {
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: var(--addarkbgcolor);
  color: white;
}

.adgeneralmaincon {
  position: relative;
  margin-left: 360px;
  display: flex;
  flex-wrap: wrap;
  margin-right: 20px;
  top: 20px;
  gap: 20px;
}

.adcpbdycon {
  flex: 1 1 1400px;
}

.adgeneralhead {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--adbasebgcolor);
  padding: 10px 0px;
  border-radius: 10px;
}

.adgeneralhead h6 {
  font-weight: 700;
}

.adgeneralbody {
  margin-top: 20px;
  background-color: var(--adbasebgcolor);
  border-radius: 10px;
}

.adgeneralsite h5,
.adgeneralclstyle h5,
.adgeneraladminstyle h5 {
  color: var(--adtextbgcolor);
  font-weight: 600;
  font-style: italic;
  margin: 0px 0px 10px 20px;
}

.adgenheadcon h5 {
  color: var(--adtextbgcolor);
  font-weight: 600;
  font-style: italic;
}

.adgenheadcon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.adgeneralst {
  background-color: var(--adbasebgcolor);
  border-radius: 10px;
}

.adgensttitle,
.adgenststcolor {
  border-bottom: 1px solid var(--adbaseactcolor);
}

.adgensttitle div,
.adgenstcolor div,
.adgenststcolor div,
.adgenstlstcolor div {
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.adgeneralclstyle,
.adgeneraladminstyle {
  margin-top: 20px;
}

.defaultCurrencyOption {
  width: 80%;
}

.adgenndefcurr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.adstecolor {
  width: 40%;
}

#adgensitetitle {
  background-color: transparent;
  color: white;
  padding: 10px 20px;
  width: 40%;
  border: none;
  border-radius: 15px;
  outline: 2px solid var(--adbaseactcolor);
}

#adgensitetitle:focus {
  outline: 2px solid var(--adbaseactcolor);
  box-shadow: 0 0 0 0 var(--adbasehvcolor);
}

#adgensitetitle:-webkit-autofill {
  box-shadow: 0 0 0 1000px var(--adbasebgcolor) inset !important;
  -webkit-text-fill-color: white !important;
}

#adgensitetitle:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px var(--adbasebgcolor) inset !important;
  -webkit-text-fill-color: white !important;
}

.adgeneralsave {
  margin-top: 20px;
}

.adsavegeneral {
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  background-color: transparent;
  color: white;
  min-width: 100px;
  font-weight: 600;
  transition: 0.5s ease-in-out;
}

.fileuploadfavicon {
  border-radius: 20px;
  font-weight: 600;
}

.fileuploadfavicon::file-selector-button {
  color: white;
  background-color: var(--adbaseactcolor);
}

.fileuploadfaviconbtn {
  border: 2px dashed var(--adbaseactcolor);
  background-color: transparent;
  color: white;
  border-radius: 20px;
  transition: 0.5s;
  padding: 10px 14px;
  cursor: pointer;
}

.fileuploadfaviconbtn:hover {
  background-color: var(--adbasehvcolor);
  border: 2px solid var(--adbasehvcolor);
  transition: 0.5s;
}
.clsavingicon {
  position: relative;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clsavingicon::before,
.clsavingicon::after {
  border: 5px solid var(--adtextbgcolor);
  border-radius: 50%;
  position: absolute;
  content: "";
  display: block;
}

.clsavingicon::before {
  width: 11.6px;
  height: 11.6px;
  border-bottom-color: transparent;
  border-left-color: transparent;
  animation: clsavingicon-1o3y8q 0.75s infinite linear reverse;
}

.clsavingicon::after {
  animation: clsavingicon-1o3y8q 0.5s infinite linear;
  height: 10px;
  width: 10px;
  border-right-color: transparent;
  border-top-color: transparent;
}

@keyframes clsavingicon-1o3y8q {
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 800px) {
  .adgeneral {
    height: 100%;
    font-size: var(--bodyfontsize);
  }
  .adgeneral .adcontrolpanel {
    display: none;
  }
  .adgeneralmaincon {
    margin-left: 20px;
    margin-bottom: 100px;
    top: 0px;
    gap: 0px;
  }
}
