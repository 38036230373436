.darkFetchingDataConCl {
  background-color: black;
  height: 500px;
  width: auto;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fetchingDataConCl {
  height: 500px;
  width: auto;
  padding: 20px;
  background-color: white;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cleditservspinner {
  position: relative;
  width: 20px;
  height: 20px;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cleditservspinner::before,
.cleditservspinner::after {
  border: 5px solid var(--sitecolor);
  border-radius: 50%;
  position: absolute;
  content: "";
  display: block;
}

.cleditservspinner::before {
  width: 11.6px;
  height: 11.6px;
  border-bottom-color: transparent;
  border-left-color: transparent;
  animation: cleditservspinner-1o3y8q 0.75s infinite linear reverse;
}

.cleditservspinner::after {
  animation: cleditservspinner-1o3y8q 0.5s infinite linear;
  height: 20px;
  width: 20px;
  border-right-color: transparent;
  border-top-color: transparent;
}

@keyframes cleditservspinner-1o3y8q {
  to {
    transform: rotate(360deg);
  }
}
