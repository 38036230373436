.bdge {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
  z-index: -1000;
}

.adbig {
  min-height: 100vh;
  display: grid;
  place-items: center;
  backdrop-filter: blur(15px);
}

.adcon {
  min-width: 20%;
  padding: 20px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.178);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.175);
}

.adloginform {
  display: grid;
  gap: 20px;
}

#adEmail,
#adPassword {
  width: 100%;
  outline: none;
  color: rgb(34, 34, 34);
  padding: 10px 10px;
  border-radius: 10px;
  background-color: transparent;
  border: 4px solid var(--adbaseactcolor);
}

#adEmail:focus,
#adPassword:focus {
  border-color: var(--adbasehvcolor);
  outline: 0;
  box-shadow: 0 0 0 0.25rem var(--adbasebgcolor);
}

#adEmail:-webkit-autofill:focus,
#adPassword:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px rgba(255, 255, 255, 0.178) inset !important;
  -webkit-text-fill-color: rgb(34, 34, 34) !important;
}

#adEmail:-webkit-autofill,
#adPassword:-webkit-autofill {
  box-shadow: 0 0 0 1000px rgba(255, 255, 255, 0.178) inset !important;
  -webkit-text-fill-color: rgb(34, 34, 34) !important;
}
.adSubmit {
  background-color: var(--adbaseactcolor);
  transition: 0.5s;
  border: 1px solid inherit;
  color: white;
  border-radius: 10px;
  margin-top: 10px;
  padding: 15px 0px;
}

.adSubmit:hover {
  transition: 0.5s;
  background-color: var(--adbasehvcolor);
}
