.adedmethodhead h6 {
  color: var(--adtextbgcolor);
  font-style: italic;
  font-weight: 700;
}

.adedmthbba,
.adeditminmaxpaymth {
  margin-top: 20px;
}

.adedpaymethstatus {
  margin-top: 20px;
  padding: 20px;
  background-color: var(--adbasebgcolor);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.adedpaymethstatus span {
  font-weight: 600;
}
