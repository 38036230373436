.cllightsignin {
  background-color: var(--bglightcolor);
  --themecolor: #fdfdfd;
  --themetextcolor: black;
}

.cldarksignin {
  background-color: var(--bgdarkcolor);
  color: white;
  --themetextcolor: white;
  --themecolor: #101010;
}

.cldarksignin,
.cllightsignin {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 716px;
  width: 100%;
}

.clsignform {
  width: 40%;
  max-width: 800px;
  flex-grow: 1;
  background-color: var(--themecolor);
  display: flex;
  border-radius: 20px;
  flex-direction: column;
}

.clloginform {
  text-align: start;
  gap: 20px;
  display: grid;
  padding: 20px;
}

.passwordInputDiv {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.clsishowPassword {
  cursor: pointer;
  position: absolute;
  font-size: 1.5rem;
  right: 10px;
  animation: fadeIn 1s;
}

.clsiemail,
.clsiusername,
.clsipassword {
  width: 100%;
  color: inherit;
  border-radius: 10px;
  background-color: inherit;
  padding: 10px;
  border: none;
  outline: 2px var(--themetextcolor) solid;
}

.clsiemail:focus,
.clsiusername:focus,
.clsipassword:focus {
  outline: 2px var(--themetextcolor) solid;
  box-shadow: 0 0 0 0 var(--themetextcolor);
}

.clsiemail:-webkit-autofill,
.clsiusername:-webkit-autofill,
.clsipassword:-webkit-autofill {
  box-shadow: 0 0 0 1000px var(--themecolor) inset !important;
  -webkit-text-fill-color: var(--themetextcolor) !important;
}

.clsiemail:-webkit-autofill:focus,
.clsiusername:-webkit-autofill:focus,
.clsipassword:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px var(--themecolor) inset !important;
  -webkit-text-fill-color: var(--themetextcolor) !important;
}

.fpass {
  text-decoration: none;
  font-weight: 600;
  color: var(--sitecolor);
  transition: 0.5s;
}

.fpass:hover {
  color: var(--stbaseactcolor);
  transition: 0.5s;
}

.clloginbtn {
  background-color: var(--sitecolor);
  border-radius: 10px;
  margin: auto;
  border: none;
  padding: 10px 0px;
  font-weight: 600;
  width: 50%;
  color: white;
}

.clloginbtn:hover {
  background: linear-gradient(
    to top right,
    var(--stbaseactcolor),
    var(--sitecolor),
    var(--sitecolor),
    var(--stbaseactcolor)
  );
  transition: 0.5s;
}

.cldarksignin .kfj {
  background-color: var(--bgdarkcolor);
}

.cllightsignin .kfj {
  background-color: var(--bglightcolor);
}

.cldarksignin .not-reg {
  border-top: 3px solid var(--bgdarkcolor);
}

.not-reg {
  border-top: 3px solid var(--bglightcolor);
  width: 100%;
  text-align: center;
}

.kfj {
  color: var(--sitecolor);
  font-weight: 600;
  padding: 20px;
  position: relative;
  text-decoration: none;
  top: 10px;
  border-radius: 20px;
}

@media (max-width: 800px) {
  .clsignform {
    max-width: 90%;
    margin: auto;
  }
  .cldarksignin,
  .cllightsignin {
    font-size: var(--bodyfontsize);
  }
}
