.ProseMirror {
  padding: 16px;
  border: 1px solid var(--adbasebgcolor);
  border-radius: 10px;
  min-height: 300px;
}

.ProseMirror-focused {
  outline: 1px solid var(--adbaseactcolor);
}

.toolbar {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid var(--adbasebgcolor);
  margin-bottom: 10px;
}

.toolbar button {
  background: none;
  color: white;
  border: none;
  transition: 0.3s;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.toolbar button:not(:last-child) {
  margin-right: 10px;
}

.toolbar button:hover {
  transition: 0.3s;
  background-color: var(--adbaseactcolor);
  font-weight: bold;
}

.toolbar button.is-active {
  background-color: var(--adbaseactcolor);
  transition: 0.3s;
  font-weight: bold;
}

.adrteupimg,
.adrteuplink {
  position: relative;
}

.adrteupimgcon,
.adrteuplinkcon {
  position: absolute;
  border: 1px solid var(--adbasebgcolor);
  border-radius: 10px;
  background-color: var(--addarkbgcolor);
  padding: 16px;
  width: 250px;
  top: 40px;
  right: -20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.adrteupimgcon h4,
.adrteuplinkcon h4 {
  margin: 0 0 10px 0;
  font-size: 16px;
  color: var(--adbaseactcolor);
}

.adrteupimgcon input[type="file"] {
  width: 100%;
  padding: 8px;
  border: 1px solid var(--adbasebgcolor);
  border-radius: 5px;
  margin-bottom: 10px;
}

.adrteupimgcon input[type="text"],
.adrteuplinkcon input[type="text"] {
  width: 100%;
  padding: 10px;
  border: none;
  outline: 1px solid var(--adbasebgcolor);
  border-radius: 10px;
  background-color: transparent;
  border-radius: 5px;
  color: inherit;
  margin-bottom: 10px;
}

.adrteupimgcon input[type="text"]:focus,
.adrteuplinkcon input[type="text"]:focus {
  outline: 1px solid var(--adbasebgcolor);
}

.adrteupimgcon input[type="file"]::file-selector-button {
  background-color: var(--adbaseactcolor);
  color: white;
  border: none;
  padding: 8px 16px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.adrteupimgcon input[type="file"]::file-selector-button:hover {
  background-color: var(--adbasehvcolor);
  transition: background-color 0.3s;
}

.adrteupimgcon button,
.adrteuplinkcon button {
  width: 100%;
  padding: 8px;
  background-color: var(--adbaseactcolor);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

.adrteupimgcon button:hover,
.adrteuplinkcon button:hover {
  background-color: var(--adbasehvcolor);
}

.adrteupimgcon .adrtealturl,
.adrteuplinkcon .adrtealturl {
  margin-top: 10px;
  text-align: center;
  color: var(--adbaseactcolor);
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
}

@media (max-width: 800px) {
  .adrteuplinkcon {
    right: -70px;
  }
}
