:root {
  --darkbkcolor: #181818;
  --lightbkcolor: #ddd;
}

.clddarkheader,
.cllightheader {
  width: 100%;
  position: fixed;
  z-index: 2;
}

.clddarkheader {
  color: white;
  background-color: var(--bgdarkcolor);
  border-bottom: 1px solid var(--darkbkcolor);
}

.cllightheader {
  background-color: var(--bglightcolor);
  border-bottom: 1px solid var(--lightbkcolor);
}

.clheadercon {
  width: 70%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}

.clheadercon .logo {
  cursor: pointer;
}

.logo img {
  width: 70px;
  height: 70px;
  pointer-events: none;
}

.clhdnavlinks {
  display: flex;
  align-items: center;
}

.clddarkheader .clhdnavlink,
.cllightheader .clhdnavlink {
  text-decoration: none;
  padding: 12px 20px;
  transition: 0.5s;
  border-radius: 10px;
  margin-right: 10px;
  text-transform: uppercase;
  background-color: transparent;
}

.clddarkheader .clhdnavlink {
  color: white;
  border: 1px solid white;
}

.cllightheader .clhdnavlink {
  color: black;
  border: 1px solid black;
}

.clhdservlink {
  text-decoration: none;
  padding: 12px 20px;
  font-weight: 600;
  text-transform: uppercase;
  transition: 0.5s;
  border-radius: 10px;
  color: white;
  background-color: var(--stbaseactcolor);
}

.clhdnavlink:hover {
  background-color: var(--stbaseactcolor);
  border: 1px solid var(--stbaseactcolor);
  transition: 0.5s;
  color: white;
}

.clhdnavlkicon {
  font-size: 1.1rem;
  margin-right: 8px;
}

.clhdhambgcon {
  transition: 0.5s;
  display: none;
  cursor: pointer;
  visibility: hidden;
  padding: 5px 10px;
  border: 1px solid var(--stbaseactcolor);
  border-radius: 10px;
}

.clhdhambg {
  font-size: 1.2rem;
}

.clhdhambgcon:hover {
  background-color: var(--stbaseactcolor);
  transition: 0.5s;
}

.clhdhambgcon:hover .clhdhambg {
  color: white;
}

.cllighthdmbmenu {
  background-color: var(--bglightcolor);
}

.cldarkhdmbmenu {
  background-color: var(--bgdarkcolor);
  color: white;
}

.cllighthdmbmenu,
.cldarkhdmbmenu {
  display: none;
  z-index: 1;
  position: fixed;
  width: 100%;
  height: 100vh;
}

.clhdmbmencon {
  position: relative;
  top: 130px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 130px);
}

.cldarkhdmbmenu .clhdmbnavlinks {
  border: 1px solid black;
}

.cllighthdmbmenu .clhdmbnavlinks {
  border: 1px solid white;
}

.clhdmbnavlinks {
  display: flex;
  width: 80%;
  border-radius: 10px;
  margin: auto;
  flex-direction: column;
}

.clmbhdnavlkicon,
.clmbhdnavlkicon {
  font-size: 1.2rem;
  margin-right: 10px;
}

.cllighthdmbmenu .clmbhdnavlink:not(:last-child) {
  border-bottom: 1px solid white;
}

.cldarkhdmbmenu .clmbhdnavlink:not(:last-child) {
  border-bottom: 1px solid black;
}

.clmbhdnavlink:first-child {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.clmbhdnavlink:last-child {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.cldarkhdmbmenu .clmbhdnavlink {
  color: white;
}

.cllighthdmbmenu .clmbhdnavlink {
  color: black;
}

.clmbhdnavlink {
  text-decoration: none;
  padding: 12px 20px;
  font-weight: 600;
  text-transform: uppercase;
  transition: 0.5s;
  background-color: transparent;
}

.clmbhdnavlink:hover {
  transition: 0.5s;
  color: white;
  background-color: var(--stbaseactcolor);
}

.clmbhdservlink {
  text-decoration: none;
  width: fit-content;
  padding: 12px 20px;
  font-weight: 600;
  text-transform: uppercase;
  transition: 0.5s;
  border-radius: 10px;
  margin: auto;
  color: white;
  background-color: var(--stbaseactcolor);
}

@media (max-width: 1000px) {
  .clheadercon {
    width: 90%;
  }
  .cllighthdmbmenu,
  .cldarkhdmbmenu {
    display: block;
  }
  .clhdhambgcon {
    display: block;
    visibility: visible;
  }
  .clddarkheader .clhdservlink,
  .cllightheader .clhdservlink,
  .clddarkheader .clhdnavlink,
  .cllightheader .clhdnavlink {
    display: none;
  }
}
