:root {
  --sitecolor: #333;
}

@media (min-width: 800px) {
  .menu-links,
  .dark-menu-links {
    display: none;
  }
}

@media (max-width: 800px) {
  .menustcon {
    min-height: 1000px;
    overflow-y: auto;
    font-size: var(--bodyfontsize);
    background-color: var(--bglightcolor);
  }
  .dark-menustcon {
    min-height: 1000px;
    overflow-y: auto;
    font-size: var(--bodyfontsize);
    background-color: var(--bgdarkcolor);
  }
  .menu-links {
    width: calc(100% - 40px);
    background-color: white;
    color: #3f3f3f;
    border-radius: 20px;
    position: absolute;
    top: 100px;
    display: block;
    margin: 0px 20px;
  }

  .dark-menu-links {
    width: calc(100% - 40px);
    background-color: black;
    color: white;
    border-radius: 20px;
    position: absolute;
    top: 100px;
    display: block;
    margin: 0px 20px;
  }

  .menu-icon {
    font-size: 26px;
    margin-right: 10px;
  }

  .menu-links ul,
  .dark-menu-links ul {
    list-style-type: none;
    padding: 0px 15px;
  }

  .next {
    float: end;
  }

  .menu-links ul li,
  .dark-menu-links ul li {
    padding: 15px 0px;
    border-bottom: 1px solid var(--bglightcolor);
  }

  .nomag {
    border-bottom: none !important;
  }

  .next {
    font-size: 24px;
  }

  .menu-links ul a,
  .dark-menu-links ul a {
    text-decoration: none;
    width: 100%;
    display: inline-block;
  }

  .menu-links ul a {
    color: black;
  }

  .dark-menu-links ul a {
    color: white;
  }

  .menu-links ul li:hover .menu-icon,
  .dark-menu-links ul li:hover .menu-icon {
    color: var(--sitecolor);
  }

  .menu-links ul li:hover .next,
  .dark-menu-links ul li:hover .next {
    color: var(--sitecolor);
  }

  .logout {
    margin-top: 50px;
    margin-bottom: 20px;
    color: red;
  }
}
