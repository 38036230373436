[data-theme="dark"] {
  --bgcolor: var(--bgdarkcolor);
  --bgmaincolor: #121212;
  --textcolor: white;
}

[data-theme="light"] {
  --bgcolor: var(--bglightcolor);
  --bgmaincolor: #f3f1f1;
  --textcolor: #333;
}

.services-container {
  width: 100%;
  min-height: 100vh;
  overflow-y: auto;
  background-color: var(--bglightcolor);
}

.dark-services-container {
  width: 100%;
  min-height: 100vh;
  background-color: var(--bgdarkcolor);
  overflow-y: auto;
}

.servicessort {
  background-color: white;
  padding: 10px;
  border-radius: 12px;
  margin-bottom: 20px;
}

.dark-servicessort {
  background-color: black;
  padding: 10px;
  border-radius: 12px;
  margin-bottom: 20px;
}

.services-table,
.clservicesmaxwifet {
  margin-left: 320px;
  margin-top: 100px;
  width: auto;
  margin-right: 20px;
  height: 100%;
}

.services-scrollX {
  min-width: 100%;
  overflow-x: auto;
  border-radius: 13px;
}

.dark-services-category {
  background-color: #272727;
  font-weight: bold;
  padding: 15px;
}

.services-category {
  background-color: #f5f5f5;
  font-weight: bold;
  padding: 15px;
}

.light-tb {
  background-color: white;
  min-width: 100%;
  overflow-x: auto;
  padding: 15px;
  border-radius: 13px;
}

.dark-tb {
  background-color: black;
  color: white;
  width: 100%;
  padding: 15px;
  border-radius: 13px;
}

.dark-cp-id {
  cursor: pointer;
  background-color: var(--bgdarkcolor);
  color: white;
  border-radius: 20px;
  padding: 5px;
  width: fit-content;
}

.cp-id {
  cursor: pointer;
  background-color: var(--bglightcolor);
  border-radius: 20px;
  padding: 5px;
  width: fit-content;
}

.services-table-header,
.services-table-data {
  padding: 20px;
}

.sd-id {
  border-top-left-radius: 10px;
}

.sd-name {
  min-width: 100px;
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.sd-descbtn {
  min-width: 150px;
}

.sd-desc {
  border-top-right-radius: 10px;
}

.show-desc {
  background: linear-gradient(
    to top right,
    var(--stbaseactcolor),
    var(--sitecolor)
  );
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 600;
  white-space: nowrap;
  font-size: calc(1em + 0.2rem);
  text-align: center;
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.description-message,
.dark-description-message {
  width: 500px;
  max-height: 80%;
  overflow-y: auto;
  animation: slideIn 1s forwards;
  border-radius: 10px;
}

.dark-description-message {
  background-color: rgb(8, 8, 8);
  color: white;
}

.description-message {
  background-color: white;
}

.logo-container {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.description-message .cancel-button {
  color: black;
}

.cancel-button {
  padding: 20px 55px;
  background-color: transparent;
  border: 1px solid var(--stbaseactcolor);
  border-radius: 90px;
  font-size: calc(1em + 0.3rem);
  width: 50%;
  font-weight: 600;
  color: white;
  cursor: pointer;
  margin: 10px 0px;
  transition: 0.5s;
}

.cancel-button:hover {
  color: white;
  background-color: var(--stbaseactcolor);
  transition: 0.5s;
}

.logo-in-description {
  width: 100px;
}

.description-message .clservdesc,
.description-message .clservname {
  background-color: var(--bglightcolor);
  border-radius: 15px;
  width: calc(100% - 40px);
  margin: auto;
}

.dark-description-message .clservdesc,
.dark-description-message .clservname {
  background-color: var(--bgdarkcolor);
  border-radius: 15px;
  width: calc(100% - 40px);
  margin: auto;
}

.clservdeschead,
.clservnamehead {
  display: flex;
  justify-content: center;
  padding: 10px 15px;
  align-items: center;
  border-bottom: 1px solid var(--stbaseactcolor);
}

.clservdeschead span,
.clservnamehead span {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    to bottom right,
    var(--stbasehvcolor),
    var(--sitecolor)
  );
  background-clip: text;
  font-weight: 600;
  font-size: calc(1em + 0.2rem);
}

.clservdesc pre {
  font-size: inherit;
  font-family: inherit;
  white-space: pre-wrap;
}

.clservnametxt {
  padding: 10px 20px;
  font-weight: 600;
}

.dark-description-message .clservquant {
  background-color: var(--bgdarkcolor);
}

.description-message .clservquant {
  background-color: var(--bglightcolor);
}

.clservquant {
  border-radius: 15px;
  width: calc(100% - 40px);
  margin: auto;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.clservquant .min,
.clservquant .charge,
.clservquant .max {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.clservquant .min span:nth-child(1),
.clservquant .charge span:nth-child(1),
.clservquant .max span:nth-child(1) {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    to bottom right,
    var(--sttextbgcolor),
    var(--sitecolor)
  );
  background-clip: text;
  font-weight: 600;
}

.clservquant .min span:nth-child(2),
.clservquant .charge span:nth-child(2),
.clservquant .max span:nth-child(2) {
  font-weight: 600;
  font-size: calc(1em + 0.2rem);
}

.clmbservicena {
  white-space: nowrap;
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 800px) {
  .services-container,
  .dark-services-container {
    font-size: var(--bodyfontsize);
  }
  .services-table,
  .clservicesmaxwifet {
    margin-left: 20px;
  }
  .services-table {
    margin-bottom: 100px;
  }
  .description-message,
  .dark-description-message {
    width: 90%;
  }
  .cancel-button {
    padding: 20px 30px;
  }
}
