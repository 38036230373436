[data-theme="dark"] {
  --bgcolor: var(--bgdarkcolor);
  --bgmaincolor: #161616;
  --textcolor: white;
}

[data-theme="light"] {
  --bgcolor: var(--bglightcolor);
  --bgmaincolor: #f3f1f1;
  --textcolor: black;
}

.clforgetpasswdcon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 716px;
  width: 100%;
}

.clforgetpasswd {
  max-width: 400px;
  margin: 20px auto;
  padding: 20px;
  background-color: transparent;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
}

.clforgetpasswd h2 {
  text-align: center;
  margin-bottom: 20px;
  color: var(--textcolor);
}

.clforgetpasswd form {
  display: flex;
  flex-direction: column;
}

.clforgetpasswd input[type="email"],
.clforgetpasswd input[type="password"],
.clforgetpasswd .code-inputs input {
  padding: 10px;
  margin: 10px 0;
  outline: 1px solid var(--textcolor);
  border: none;
  border-radius: 5px;
  color: var(--textcolor);
  background-color: transparent;
}

.clforgetpasswd input[type="email"]:focus,
.clforgetpasswd input[type="password"]:focus,
.clforgetpasswd .code-inputs input:focus {
  outline: 1px solid var(--textcolor);
  box-shadow: 0 0 0 0 var(--textcolor);
}

.clforgetpasswd .code-inputs {
  display: flex;
  justify-content: space-between;
}

.clforgetpasswd .code-inputs input {
  width: 40px;
  text-align: center;
  border-radius: 5px;
  border: 1px solid var(--stbasehvcolor);
}

.clforgetpasswd button {
  padding: 10px;
  margin: 20px 0;
  background-color: var(--stbaseactcolor);
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.clforgetpasswd button:hover {
  background-color: var(--stbasehvcolor);
}

.clforgetpasswd .message {
  margin: 20px 0;
  padding: 10px;
  background-color: var(--stbasebgcolor);
  border-left: 5px solid var(--stbaseactcolor);
  color: var(--textcolor);
  border-radius: 5px;
}

.clforgetpasswd .password-rules {
  margin: 20px 0;
  padding: 10px;
  background-color: var(--stbasebgcolor);
  border-left: 5px solid var(--stbasehvcolor);
  color: var(--textcolor);
  border-radius: 5px;
}

.clforgetpasswd .password-rules ul {
  margin: 10px 0;
  padding-left: 20px;
  list-style-type: disc;
}

.clforgetpasswd .password-rules li {
  margin-bottom: 5px;
}

.clforgetpasswd .success {
  margin: 20px 0;
  padding: 15px;
  background-color: #27ae60;
  border-radius: 5px;
  color: #fff;
  text-align: center;
}
