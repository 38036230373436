:root {
  --darkbkcolor: #181818;
  --lightbkcolor: #ddd;
}

.cldarkfoot,
.cldarkfoot .clfootsocials {
  border-top: 1px solid var(--darkbkcolor);
  color: white;
}

.cllightfoot,
.cllightfoot .clfootsocials {
  border-top: 1px solid var(--lightbkcolor);
}

.cldarkfoot,
.clfootsocials,
.cllightfoot {
  width: 100%;
}

.clfootcon,
.clfootsocialscon {
  width: 70%;
  margin: auto;
  display: flex;
  padding: 20px 0px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.clfootfcol,
.clfootservices,
.clfootnavlinks {
  display: flex;
  flex-direction: column;
  flex: 1 1 400px;
  margin: 20px 0px;
}

.clfootfcol img {
  width: 100px;
}

.clfootnavlinks a,
.clfootservices a {
  text-decoration: none;
  transition: 0.5s;
  margin-bottom: 10px;
  color: inherit;
}

.clfootnavlinks a:hover,
.clfootservices a:hover {
  text-decoration: underline;
  transition: 0.5s;
}

.clfootsocials .clftsocials a {
  color: inherit;
  margin-right: 20px;
  transition: 0.5s;
  text-decoration: none;
}

.clfootsocials .clftsocials a:hover {
  color: var(--stbaseactcolor);
  transition: 0.5s;
}

@media (max-width: 1000px) {
  .clfootcon,
  .clfootsocialscon {
    width: 90%;
  }
}
