[data-theme="dark"] {
  --bgcolor: var(--bgdarkcolor);
  --bgmaincolor: #121212;
  --textcolor: white;
}

[data-theme="light"] {
  --bgcolor: var(--bglightcolor);
  --bgmaincolor: #f3f1f1;
  --textcolor: #333;
}

.bd-dx {
  background-color: var(--sitecolor);
  min-width: 10px;
  color: white;
  text-align: center;
  padding: 7px;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 100px;
  white-space: nowrap;
}

.adorderservID {
  cursor: pointer;
}

.light-table {
  background-color: white;
  margin-top: 17px;
  min-width: 100%;
  border-radius: 10px;
  overflow-x: auto;
}

.dark-table {
  color: white;
  background-color: black;
  margin-top: 17px;
  min-width: 100%;
  border-radius: 10px;
  overflow-x: auto;
}
.uru {
  width: 100%;
}
.bd-id {
  border-top-left-radius: 10px;
}
.bd-date {
  border-top-right-radius: 10px;
}
tr .orders-table-data,
.order-lin,
.orders-table-head {
  padding: 20px;
}
.orders-url {
  text-decoration: none;
  color: var(--sitecolor);
}
.orders-url:hover {
  text-decoration: underline;
}
.order-lin {
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.orders-service {
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.orders-date {
  min-width: 200px;
}

.darkordersearchcon {
  background-color: black;
  margin-left: auto;
  color: white;
  height: 70px;
  padding: 10px 20px;
  border-radius: 10px;
  width: fit-content;
}

.ordersearchcon {
  background-color: white;
  margin-left: auto;
  color: black;
  height: 70px;
  padding: 10px 20px;
  border-radius: 10px;
  width: fit-content;
}

.orsearIcon {
  font-size: 25px;
}

.orderssearchbtn {
  background-color: transparent;
  border: none;
  color: black;
}

.darkorderssearchbtn {
  background-color: transparent;
  border: none;
  color: white;
}

.orderssearchbtn p,
.darkorderssearchbtn p {
  font-weight: 700;
  transition: 0.5s;
}

.orderssearchbtn:hover p,
.darkorderssearchbtn:hover p {
  color: var(--sitecolor);
  transition: 0.5s;
}

.searchorderDiv {
  position: relative;
}

#darkordersearch {
  width: 100%;
  border-radius: 20px;
  padding: 10px 20px;
  color: white;
  border: 1px solid var(--sitecolor);
  margin-bottom: 12px;
  background-color: transparent;
  height: auto;
}

#darkordersearch:focus {
  border-color: var(--sitecolor);
  outline: 0;
  box-shadow: 0 0 0 0.25rem var(--sitecolor);
}

#darkordersearch:-webkit-autofill {
  box-shadow: 0 0 0 1000px black inset !important;
}

#darkordersearch:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px black inset !important;
}

#ordersearch {
  width: 100%;
  border-radius: 20px;
  padding: 10px 20px;
  border: 1px solid var(--sitecolor);
  margin-bottom: 12px;
  color: black;
  background-color: transparent;
  height: auto;
}

#ordersearch:focus {
  border-color: var(--sitecolor);
  outline: 0;
  box-shadow: 0 0 0 0.25rem var(--sitecolor);
}

#ordersearch:-webkit-autofill {
  box-shadow: 0 0 0 1000px white inset !important;
}

#ordersearch:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px white inset !important;
}

.orsendsearch {
  color: var(--sitecolor);
  font-size: 20px;
  top: 20%;
  cursor: pointer;
  position: absolute;
  right: 5%;
}

.clordersrefillbtn {
  padding: 10px 20px;
  border: none;
  border-radius: 15px;
  font-weight: 600;
  transition: background-color 0.5s;
  color: white;
  background-color: var(--stbaseactcolor);
}

.clordersrefillbtn:hover {
  background-color: var(--stbaseactcolor);
}

.clmborderscon {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  width: 100%;
  color: var(--textcolor);
}

.clmborderdet {
  background-color: var(--bgmaincolor);
  cursor: pointer;
  border-radius: 10px;
  transition: transform 0.5s ease;
}

.clmborderdet:hover {
  transform: translateY(-10px);
}

.clmborderdethead {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  gap: 20px;
  border-bottom: 1px solid var(--stbaseactcolor);
}

.clmborderdetbody {
  padding: 20px;
  display: flex;
  overflow: hidden;
  align-items: center;
  max-width: 100%;
  justify-content: space-between;
  gap: 20px;
}

.clmborderurl {
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-decoration: none;
  color: var(--sttextbgcolor);
}

.clmbservice {
  cursor: pointer;
  white-space: nowrap;
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: 0.5s;
}

.clmbservice:hover {
  color: var(--sttextbgcolor);
  transition: 0.5s;
}
