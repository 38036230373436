.adcontrolpanel {
  flex: 1 1 200px;
}

.adcpmbmaincon {
  display: flex;
  flex: 1 1 200px;
}

#adidcpmbmaincon {
  margin: 20px 20px 20px 360px;
}

.adcpmbnav {
  display: none;
}

@media (max-width: 800px) {
  .adcpmbmaincon {
    margin: 20px;
  }
  .adcpmbnav {
    display: block;
  }
}

.adcpheadtxt {
  width: 100%;
  background-color: var(--adbasebgcolor);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 10px 0px;
}

.adcpheadtxt h6 {
  font-weight: 600;
  color: var(--adtextbgcolor);
}

.adcpbodycon {
  margin-top: 20px;
  border-radius: 10px;
  background-color: var(--adbasebgcolor);
}

.adcpbodycon ul {
  list-style: none;
  width: 100%;
}

.adcpbodycon ul li {
  margin-left: -30px;
}

.adcpbodycon ul li .adcplk {
  padding: 20px;
  text-decoration: none;
  align-items: center;
  width: 100%;
  color: white;
  display: inline-flex;
  font-weight: 600;
  border-radius: 10px;
  transition: 0.5s ease-in-out;
}

.adcpbodycon ul li .adcplkactive {
  padding: 20px;
  text-decoration: none;
  align-items: center;
  width: 100%;
  color: white;
  font-weight: 600;
  display: inline-flex;
  border-radius: 10px;
  transition: 0.5s ease-in-out;
  background-color: var(--adbaseactcolor);
}

.adcpbodycon ul li .adcplk:hover {
  transition: 0.5s ease-in-out;
  background-color: var(--adbaseactcolor);
}

.adcpbodycon .adcdsic {
  margin-right: 20px;
  width: 30px;
}

.adcpbodycon .adcdeic {
  float: right;
  transition: 0.5s ease-in-out;
  font-size: 1.3rem;
  color: white;
}

@media (max-width: 800px) {
  .adcontrolpanel {
    font-size: var(--bodyfontsize);
  }
  #adidcpmbmaincon {
    margin: 20px 20px 100px 20px;
  }
}
