.adallsel {
  position: absolute;
}

.adulsel {
  position: relative;
  padding: 10px;
  top: 20px;
  -webkit-box-shadow: 1px 2px 7px 4px var(--addarkbgcolor);
  -moz-box-shadow: 1px 2px 7px 4px var(--addarkbgcolor);
  box-shadow: 1px 2px 7px 4px var(--addarkbgcolor);
  background-color: var(--adbasebgcolor);
  border-radius: 16px;
  list-style: none;
}

.adliuul {
  margin-bottom: 10px;
}

.adbtnsel {
  white-space: nowrap;
  text-align: start;
  border-radius: 10px;
  padding: 10px 30px;
  transition: 0.5s;
  width: 100%;
  display: inline-block;
  font-weight: 700;
  margin-left: auto;
  background-color: transparent;
  border: none;
  color: white;
}

.adsellent{
  color: var(--adtextbgcolor);
}

.adbtnsel:hover {
  background-color: var(--adbaseactcolor);
  transition: 0.5s;
}

.adbtnsel:hover .aduselicon {
  color: var(--adbasehvcolor);
  transition: 0.3s;
}

.aduselicon {
  vertical-align: middle;
  margin-right: 10px;
  margin-left: -20px;
  font-size: 25px;
}

@media(max-width:420px){
  .adallsel {
    right: 10px;
  }
}