.adfaqs {
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: var(--addarkbgcolor);
  color: white;
}

.adfaqsmaincon {
  position: relative;
  margin-left: 360px;
  display: flex;
  flex-wrap: wrap;
  margin-right: 20px;
  top: 20px;
  gap: 20px;
}

.adfaqhead {
  background-color: var(--adbasebgcolor);
  display: flex;
  margin-bottom: 20px;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0px;
  border-radius: 10px;
  width: 100%;
}

.adfaqhead span {
  font-weight: 600;
  font-size: inherit;
}

.adfaqheadadd {
  border: none;
  background-color: transparent;
  font-size: inherit;
  padding: 0px;
  color: var(--adtextbgcolor);
  font-weight: 600;
}

.adfaqaddfrm {
  display: grid;
  gap: 20px;
}

.adfaqsaddfrmbtn {
  padding: 20px;
  border-radius: 20px;
  background-color: var(--adbasebgcolor);
  transition: 0.5s;
  color: white;
  border: none;
}

.adfaqsaddfrmbtn:hover {
  background-color: var(--adbaseactcolor);
  transition: 0.5s;
}

.adfaqstable {
  width: 100%;
  overflow: hidden;
  background-color: var(--adbasebgcolor);
  border-radius: 20px;
}

.adfaqstable table {
  width: 100%;
}

.adfaqrow:hover {
  background-color: var(--adbaseactcolor);
  transition: background-color 0.3s;
}

.adfaqrow {
  transition: background-color 0.3s;
}

.adfaqrow td {
  padding: 20px;
}

.adfaqdeletebtn {
  border-radius: 10px;
  background-color: var(--adbaseactcolor);
  border: none;
  transition: 0.3s;
  color: white;
  padding: 5px 10px;
}

.adfaqdeletebtn:hover {
  background-color: var(--adbasehvcolor);
  transition: 0.3s;
}

.adfaqrow:not(:last-child) {
  border-bottom: 1px solid var(--adbaseactcolor);
}

@media (max-width: 800px) {
  .adfaqs {
    height: 100%;
    font-size: var(--bodyfontsize);
  }
  .adfaqs .adcontrolpanel {
    display: none;
  }
  .adfaqsmaincon {
    margin-left: 20px;
    top: 0px;
    gap: 0px;
  }
}
