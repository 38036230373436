.adasadmin-support {
  background-color: var(--addarkbgcolor);
  color: white;
  height: 100vh;
  overflow: auto;
}

.adasupportmaincon {
  position: relative;
  margin-left: 360px;
  margin-right: 20px;
  margin-bottom: 30px;
  top: 20px;
}

.adassupport-container {
  margin: 0 auto;
  max-width: 1200px;
  padding: 20px;
  background-color: var(--adbasebgcolor);
  border-radius: 20px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

.adassupport-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.adassupportsearch .icon {
  font-size: 1.3rem;
  color: white;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.adassupportsearch .icon:hover {
  transform: scale(1.1);
}

.adassupport-search-input {
  width: 200px;
  padding: 8px;
  border: none;
  outline: 1px solid var(--adbasehvcolor);
  border-radius: 5px;
  background-color: var(--adbaseactcolor);
  color: white;
}

.adasbulk-action-button {
  padding: 10px 20px;
  background-color: var(--adbaseactcolor);
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.adasbulk-action-button:hover {
  background-color: var(--adbasehvcolor);
}

.adassupport-tickets {
  width: 100%;
  overflow-x: auto;
  margin-top: 20px;
}

.adsupporttables {
  width: 100%;
  border-collapse: collapse;
  background-color: var(--adbasebgcolor);
  border-radius: 10px;
}

.adsupporttables th,
.adsupporttables td {
  padding: 15px;
  text-align: left;
  vertical-align: middle;
}

.adsupporttables tr:not(:last-child) {
  border-bottom: 1px solid var(--adbaseactcolor);
}

.adsupporttables th {
  background-color: var(--adbaseactcolor);
  color: white;
}

.adsupporttables th:first-child {
  border-top-left-radius: 10px;
}

.adsupporttables th:last-child {
  border-top-right-radius: 10px;
}

.adsupporttables tr:hover {
  background-color: var(--addarkbgcolor);
}

.adsupporttables td a {
  color: var(--adtextbgcolor);
  text-decoration: none;
  padding: 10px 15px;
  background-color: var(--adbaseactcolor);
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;
}

.adsupporttables td a:hover {
  background-color: var(--adbasehvcolor);
  color: white;
}

@media (max-width: 800px) {
  .adasupportmaincon {
    margin-left: 20px;
    font-size: var(--bodyfontsize);
    margin-bottom: 100px;
  }
  .adassupport-container {
    padding: 15px;
  }

  .bulk-action-button {
    width: 100%;
    margin-top: 10px;
  }

  .support-toolbar {
    flex-direction: column;
  }

  .adsupporttables th,
  .adsupporttables td {
    padding: 10px;
  }
}
