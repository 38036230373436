.switch-container .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch-container .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-container .switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--adbasehvcolor);
  border-radius: 34px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch-container .switch .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  border-radius: 50%;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch-container .switch input:checked + .slider {
  background-color: rgb(18, 199, 140);
}

.switch-container .switch input:focus + .slider {
  box-shadow: 0 0 1px rgb(18, 199, 140);
}

.switch-container .switch input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

@media (max-width: 800px) {
  .switch-container .switch {
    width: 40px;
    height: 24px;
  }

  .switch-container .switch .slider:before {
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
  }

  .switch-container .switch input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }
}
