:root {
  --sitecolor: #333;
}

.api {
  background-color: var(--bglightcolor);
  color: black;
  min-height: 100vh;
  overflow-y: auto;
}

.dark-api {
  background-color: var(--bgdarkcolor);
  color: white;
  min-height: 100vh;
  overflow-y: auto;
}

.api-container {
  position: relative;
  top: 100px;
  margin-left: 320px;
  margin-right: 20px;
  margin-bottom: 240px;
}

.api-head {
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}

.dark-api-head {
  border-bottom: 1px solid rgb(20, 20, 20);
  margin-bottom: 10px;
}

.api-details {
  width: 100%;
  padding: 15px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 2px 5px 0px var(--sitecolor);
}

.dark-api-details {
  width: 100%;
  color: white;
  padding: 15px;
  background-color: black;
  border-radius: 10px;
  box-shadow: 0px 2px 5px 0px var(--sitecolor);
}

.api-format {
  margin-top: 20px;
}
#dark-table-brd {
  border: 1px solid rgb(20, 20, 20);
}

#dark-table-brd > thead > tr > th,
#dark-table-brd > tbody > tr > td {
  border: 1px solid rgb(20, 20, 20);
  background-color: black;
  color: white;
}

#table-brd {
  border: 1px solid rgb(20, 20, 20);
}

#table-brd > thead > tr > th,
#table-brd > tbody > tr > td {
  border: 1px solid #ddd;
}

.apiurl {
  color: var(--sitecolor);
}

#apiRes {
  margin-top: 10px;
  width: 100%;
  padding: 15px;
  background-color: #f2f2f2;
  border: 1px solid #ddd;
  border-radius: 5px;
}

#darkApiRes {
  margin-top: 10px;
  width: 100%;
  padding: 15px;
  background-color: rgb(20, 20, 20);
  border: 1px solid rgb(20, 20, 20);
  border-radius: 5px;
}

@media (max-width: 800px) {
  .api,
  .dark-api {
    font-size: var(--bodyfontsize);
  }
  .api-container {
    margin-left: 20px;
    margin-right: 20px;
  }
}
