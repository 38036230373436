.bfct {
  height: 100dvh;
  background-color: #fdfaff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.darkbfct {
  height: 100dvh;
  background-color: #0c0c0c;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cltloading {
  width: 70px;
  height: 70px;
  animation: scale-zoomanim 3s infinite ease-in-out;
}

@keyframes scale-zoomanim {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(2);
  }
}
