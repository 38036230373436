.adblogs {
  width: 100%;
  height: 100%;
  background-color: var(--addarkbgcolor);
  color: white;
}

.adblogsmaincon {
  position: relative;
  top: 20px;
  margin: 0px 20px 50px 360px;
}

.adblogsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.adblogItem {
  background-color: var(--adbasebgcolor);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.adblogItem:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.adblogTitle {
  font-weight: bold;
  color: var(--adbasehvcolor);
  margin-bottom: 10px;
}

.adblogExcerpt {
  color: white;
  margin-bottom: 20px;
}

.adblogbuttons {
  display: flex;
  gap: 20px;
}

.adviewmbtnicon {
  font-size: 1.2rem;
}

.adviewMoreButton {
  padding: 10px 20px;
  background-color: var(--adbaseactcolor);
  color: white;
  border: none;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.adviewMoreButton:hover {
  background-color: var(--adbasehvcolor);
}

.addBlogContainer {
  background-color: var(--adbasebgcolor);
  padding: 20px;
  border-radius: 10px;
  margin-top: 40px;
}

.addBlogContainer h2 {
  margin-bottom: 30px;
}

.adblogTitleInput,
.adblogExcerptInput {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  color: white;
  border: none;
  border-radius: 5px;
  outline: 1px solid var(--adbaseactcolor);
  background-color: transparent;
}

.adblogExcerptInput {
  height: 100px;
}

.adblogTitleInput:focus,
.adblogExcerptInput:focus {
  outline: 1px solid var(--adbaseactcolor);
  box-shadow: 0 0 0 0 var(--adbaseactcolor);
}

.addBlogButton {
  padding: 10px 20px;
  background-color: var(--adbaseactcolor);
  color: white;
  border: none;
  border-radius: 10px;
  margin-top: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.addBlogButton:hover {
  background-color: var(--adbasehvcolor);
}

.adsaveblogbtn {
  padding: 20px;
  border-radius: 10px;
  margin-top: 10px;
  background-color: var(--adbasebgcolor);
  transition: 0.5s;
  color: white;
  border: none;
}

.adsaveblogbtn:hover {
  background-color: var(--adbaseactcolor);
  transition: 0.5s;
}

.adblogheadtxt {
  font-weight: bold;
  margin-bottom: 30px;
}

@media (max-width: 800px) {
  .adblogsmaincon {
    margin: 0px 20px 100px 20px;
    font-size: var(--bodyfontsize);
  }
}
