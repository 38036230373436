.adservicessort {
  background-color: var(--adbaseactcolor);
  padding: 10px;
  border-radius: 12px;
  margin-bottom: 20px;
}

.sortOptions {
  width: 400px;
  cursor: pointer;
  font-weight: 600;
}

.adservicestable {
  background-color: var(--adbasebgcolor);
  border-radius: 12px;
  min-width: 100%;
  overflow: hidden;
  margin-bottom: 100px;
}

.adservicestable table {
  width: 100%;
}

.adserviceshead,
.adservicesbody {
  padding: 20px;
  white-space: nowrap;
}

.adservicesbody {
  border-top: 1px solid var(--adbaseactcolor);
}

.adservicescatminwitdth {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.adservnameacat {
  display: flex;
  flex-direction: column;
}

.adservicestbbdcat {
  color: var(--adbasehvcolor);
  cursor: pointer;
}

.adservicesminwitdth {
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.adservicesname,
.adservicesproviderurl,
.adservicesprice {
  color: var(--adtextbgcolor);
  width: fit-content;
}

.adservicesname {
  cursor: pointer;
}

.adservicesproviderurl {
  text-decoration: none;
  font-weight: 600;
}

.adservicesactions {
  position: absolute;
  right: 20px;
}

.adallservicesactions {
  position: relative;
}

.adserviceactioncon {
  position: relative;
  margin-bottom: 100px;
  top: 20px;
  background-color: var(--adbasebgcolor);
  max-width: 250px;
  -webkit-box-shadow: 1px 2px 7px 4px var(--addarkbgcolor);
  -moz-box-shadow: 1px 2px 7px 4px var(--addarkbgcolor);
  box-shadow: 1px 2px 7px 4px var(--addarkbgcolor);
  padding: 20px;
  border-radius: 20px;
}

.adallserviceactioncon {
  position: absolute;
  margin-bottom: 100px;
  top: 20px;
  width: fit-content;
}

.adallserviceactioncon ul {
  padding: 20px;
  border-radius: 20px;
  background-color: var(--adbasebgcolor);
  -webkit-box-shadow: 1px 2px 7px 4px var(--addarkbgcolor);
  -moz-box-shadow: 1px 2px 7px 4px var(--addarkbgcolor);
  box-shadow: 1px 2px 7px 4px var(--addarkbgcolor);
  width: 100%;
  list-style: none;
}

.adallserviceactioncon li {
  margin-left: -12px;
}

.adservmax20 {
  max-width: 20px;
}

.adservmax50 {
  max-width: 50px;
}

.adserviceactionbtn {
  background-color: transparent;
  font-size: inherit;
  margin-bottom: 10px;
  padding: 12px;
  text-align: start;
  color: white;
  width: 100%;
  font-weight: 600;
  border-radius: 12px;
  border: none;
  white-space: nowrap;
  transition: 0.5s;
}

.adserviceactionbtn:hover {
  background-color: var(--adbaseactcolor);
  transition: 0.5s;
}

.adserviceactionbtn:hover .adserviceactionbtnicon {
  color: var(--adbasehvcolor);
  transition: 0.5s;
}

.adservicestbmaxwidth {
  min-width: 200px;
}

.adserviceactionbtnicon {
  transition: 0.5s;
  font-size: calc(1.1rem);
  margin-right: 10px;
}

.adservicesactionoverlaycon {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 0;
  animation: fadeIn 0.3s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.adservicesactionoverlay {
  background-color: var(--addarkbgcolor);
  color: white;
  padding: 20px;
  width: fit-content;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  height: fit-content;
  border-radius: 10px;
  opacity: 0;
  transform: scale(0.8);
  animation: slideIn 0.3s ease-in-out forwards;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.adservoverlayhdtxt {
  font-style: italic;
  text-transform: uppercase;
  color: var(--adtextbgcolor);
}

.categoryOptions {
  width: 350px;
  margin-top: 20px;
  font-weight: 600;
  cursor: pointer;
}

.asservstatus {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.adservicestatusind {
  text-transform: uppercase;
  color: var(--adtextbgcolor);
  margin-top: 0.2em;
  margin-left: 1.9em;
}

.adservicedeletebtn {
  color: rgb(240, 14, 14);
}

.adservicesselalltxt {
  white-space: nowrap;
}

.adservicesselalltxt span {
  color: var(--adtextbgcolor);
}

.adservrow {
  background-color: inherit;
  transition: 0.4s;
}

.adservrow:hover {
  background-color: var(--adbaseactcolor);
  transition: 0.4s;
}

@media (max-width: 800px) {
  .sortOptions,
  .categoryOptions {
    width: 200px;
  }
  .adservicescatminwitdth {
    max-width: 100px;
  }
  .adserviceactioncon {
    max-width: 200px;
    padding: 10px;
  }
  .stuserbalancebtn{
    font-size: 0.7rem;
    padding: 0.7rem;
  }
  .adallserviceactioncon {
    right: 100px;
  }
}
