.adproviders {
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: var(--addarkbgcolor);
  color: white;
}

.adprovidersmaincon {
  position: relative;
  margin-left: 360px;
  display: flex;
  flex-wrap: wrap;
  margin-right: 20px;
  top: 20px;
  gap: 20px;
}

.adprovtbtdminwdtg {
  min-width: 200px;
}

.adprovthshead {
  background-color: var(--adbasebgcolor);
  display: flex;
  margin-bottom: 20px;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0px;
  border-radius: 10px;
  width: 100%;
}

.adprovthshead span {
  font-size: inherit;
  font-weight: 600;
}

.adaddprovmet {
  border: none;
  background-color: transparent;
  padding: 0px;
  color: var(--adtextbgcolor);
  font-size: inherit;
  font-weight: 600;
}

.adsyncproviders {
  background-color: var(--adbaseactcolor);
  padding: 20px;
  border-radius: 20px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addeleteProv {
  cursor: pointer;
  padding: 8px 14px;
  font-weight: 600;
  border-radius: 10px;
  background-color: transparent;
  color: red;
  border: 1px solid var(--adbasehvcolor);
  transition: 0.4s;
}

.addeleteProv:hover {
  background-color: var(--adbaseactcolor);
  transition: 0.4s;
}

.adprovtrtdimg {
  border: 5px solid var(--adbaseactcolor);
  border-radius: 10px;
  width: 50px;
  object-fit: cover;
}

@media (min-width: 800px) and (max-width: 1090px) {
  .adoaymethstbtr td {
    display: inline-block;
  }
}

@media (max-width: 800px) {
  .adproviders {
    height: 100dvh;
    font-size: var(--bodyfontsize);
  }
  .adproviders .adcontrolpanel {
    display: none;
  }
  .adprovidersmaincon {
    margin-left: 20px;
    top: 0px;
    gap: 0px;
    margin-bottom: 100px;
  }
}
